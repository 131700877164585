import httpClient from '../auth/interceptor';

const API_URL = '/organizations';

class OrganizationService {
    getAll() {
        return httpClient.get(API_URL);
    }

    getByCreatedBy(cogId) {
        return httpClient.get(`${API_URL}/createdBy/${cogId}`);
    }

    getQuestions() {
        return httpClient.get(`${API_URL}/questions`);
    }

    getById(id) {
        return httpClient.get(`${API_URL}/${id}`);
    }

    getStepOne(cogId) {
        return httpClient.get(`${API_URL}/${cogId}/step1`);
    }

    create(organization) {
        return httpClient.post(API_URL, organization);
    }

    update(id, organization) {
        return httpClient.put(`${API_URL}/${id}`, organization);
    }

    uploadPhoto(id, photo) {
        return httpClient.put(`${API_URL}/${id}/photo`, photo, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${photo._boundary}`
            }
        });
    }

    saveMap(id, map) {
        return httpClient.post(`${API_URL}/${id}/map`, map);
    }

    getNewQuiz(id) {
        return httpClient.get(`${API_URL}/${id}/new-questions`);
    }

    saveNewQuiz(id, quiz) {
        return httpClient.post(`${API_URL}/${id}/new-questions`, quiz);
    }

    getConsad(id, year) {
        return httpClient.get(`${API_URL}/${id}/consad/${year}`);
    }

    saveConsad(id, quiz) {
        return httpClient.post(`${API_URL}/${id}/consad`, quiz);
    }

    getG4Quiz(id) {
        return httpClient.get(`${API_URL}/${id}/g4`);
    }

    saveG4Quiz(id, quiz) {
        return httpClient.post(`${API_URL}/${id}/g4`, quiz);
    }

    deleteG4Quiz(id) {
        return httpClient.delete(`${API_URL}/${id}/g4`);
    }
}

export default new OrganizationService();
