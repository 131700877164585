import { createI18n } from 'vue-i18n';
import en from './en';
import es from './es';
import pt from './pt';

const messages = {
    en,
    es,
    pt
}

const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'pt'

export default createI18n({
    locale: lang,
    messages
})