import Notifications from '@kyvg/vue3-notification';
import Toaster from '@meforma/vue-toaster';
import $ from 'jquery';
import Maska from 'maska';
import { createApp } from 'vue';
// Import the CSS or use your own!
import CKEditor from '@ckeditor/ckeditor5-vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import { GDialog } from 'gitart-vue-dialog';
import 'gitart-vue-dialog/dist/style.css';
import 'vue-toastification/dist/index.css';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import App from './App.vue';
import i18n from './langs/lang';
import router from './router';
import './static/css/popper.css';
import store from './store';

createApp(App)
    .use(router)
    .use(store)
    .use(Notifications)
    .component('EasyDataTable', Vue3EasyDataTable)
    .component('Datepicker', Datepicker)
    .use(Toaster, { position: 'top-right', closeButton: true })
    .use(Maska)
    .use($)
    .use(i18n)
    .use(FloatingVue)
    .use(CKEditor)
    .component('GDialog', GDialog)
    .mount('#app');
