<template>
  <div id="landing">
    <header id="header">
      <div class="header-div-img">
        <img
          src="./../images/logo-seall.png"
          alt="Logo SEALL"
          class="img-logo"
        />
      </div>
      <div class="header-div-links">
        <router-link
          to="/"
          :class="validateHeader('home')"
          class="links-header"
          >{{ $t("landing.header.home") }}</router-link
        >
        <a
          :class="validateHeader('who')"
          @click="toggleHeader('who')"
          href="#vi-section-sixth"
          class="links-header"
          >{{ $t("landing.header.who") }}</a
        >
        <a
          :class="validateHeader('we_do')"
          @click="toggleHeader('we_do')"
          href="#vi-sixth-second"
          class="links-header"
        >
          {{ $t("landing.header.we_do") }}
        </a>
        <a
          :class="validateHeader('for')"
          @click="toggleHeader('for')"
          href="#vi-section-second"
          class="links-header"
        >
          {{ $t("landing.header.for") }}
        </a>
        <a
          :class="validateHeader('solucion')"
          @click="toggleHeader('solucion')"
          href="#vi-sixth-fourth"
          class="links-header"
        >
          {{ $t("landing.header.solucion") }}
        </a>
        <a
          class="first-button mailto"
          href="mailto:support@seallintelligence.com"
        >
          {{ $t("landing.header.contact_us") }}
        </a>
      </div>
      <div style="margin: 0vh 0.5vw" class="header-div-langs">
        <img
          style="margin: 0vh 0.25vw"
          src="./../../langs/pt.png"
          alt
          v-on:click="set_lang(langs[2][0])"
        />
        <img
          style="margin: 0vh 0.25vw"
          src="./../../langs/en.png"
          alt
          v-on:click="set_lang(langs[0][0])"
        />
        <img
          style="margin: 0vh 0.25vw"
          src="./../../langs/es.png"
          alt
          v-on:click="set_lang(langs[1][0])"
        />
      </div>
    </header>

    <main>
      <section class="vi-section-first" id="vi-section-first">
        <div class="vi-first-background">
          <div class="main-login-svgs-create">
            <svg width="140" height="140" class="svg-ods1">
              <circle cx="70" cy="70" r="70" fill="#C5192D" />
              <foreignObject x="10" y="20" width="120" height="120">
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <div
                    class="map-radar-card-text"
                    style="
                      display: flex;
                      flex-direction: row;
                      height: 30%;
                      width: 100%;
                    "
                  >
                    <p
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-items: center;
                        justify-content: flex-end;
                        padding: 0vh 0vw;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Roboto', sans-serif;
                        font-size: 30px;
                        width: 30%;
                        font-weight: bold;
                        font-size: 28px;
                      "
                    >
                      4
                    </p>
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        padding: 2px 5px 2px 5px;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Roboto', sans-serif;
                        font-weight: bold;
                        font-size: 10px;
                        text-transform: uppercase;
                        width: 70%;
                      "
                      >{{ $t("map.ods.ods4.name") }}</span
                    >
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      justify-content: center;
                      height: 80%;
                    "
                  >
                    <img style="width: 50%" src="./../images/ods-icon4.svg" />
                  </div>
                </div>
              </foreignObject>
            </svg>
            <svg width="140" height="140" class="svg-ods15">
              <circle cx="70" cy="20" r="70" fill="#56C02B" />
              <foreignObject x="10" y="0" width="120" height="120">
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      justify-content: center;
                      height: 80%;
                    "
                  >
                    <img style="width: 50%" src="./../images/ods-icon15.svg" />
                  </div>
                </div>
              </foreignObject>
            </svg>
            <svg width="140" height="140" class="svg-ods6">
              <circle cx="70" cy="70" r="70" fill="#26BDE2" />
              <foreignObject x="10" y="20" width="120" height="120">
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <div
                    class="map-radar-card-text"
                    style="
                      display: flex;
                      flex-direction: row;
                      height: 30%;
                      width: 100%;
                    "
                  >
                    <p
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-items: center;
                        justify-content: flex-end;
                        padding: 0vh 0vw;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-size: 30px;
                        width: 30%;
                        font-weight: bold;
                        font-size: 28px;
                      "
                    >
                      6
                    </p>
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        padding: 2px 5px 2px 5px;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-weight: bold;
                        font-size: 10px;
                        text-transform: uppercase;
                        width: 70%;
                      "
                      >{{ $t("map.ods.ods6.name") }}</span
                    >
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      justify-content: center;
                      height: 80%;
                    "
                  >
                    <img style="width: 50%" src="./../images/ods-icon6.svg" />
                  </div>
                </div>
              </foreignObject>
            </svg>
            <svg width="140" height="140" class="svg-ods3">
              <circle cx="70" cy="70" r="70" fill="#4C9F38" />
              <foreignObject x="10" y="20" width="120" height="120">
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <div
                    class="map-radar-card-text"
                    style="
                      display: flex;
                      flex-direction: row;
                      height: 30%;
                      width: 100%;
                    "
                  >
                    <p
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-items: center;
                        justify-content: flex-end;
                        padding: 0vh 0vw;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-size: 30px;
                        width: 30%;
                        font-weight: bold;
                        font-size: 28px;
                      "
                    >
                      3
                    </p>
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        padding: 2px 5px 2px 5px;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-weight: bold;
                        font-size: 10px;
                        text-transform: uppercase;
                        width: 70%;
                      "
                      >{{ $t("map.ods.ods3.name") }}</span
                    >
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      justify-content: center;
                      height: 80%;
                    "
                  >
                    <img style="width: 50%" src="./../images/ods-icon3.svg" />
                  </div>
                </div>
              </foreignObject>
            </svg>
            <svg width="140" height="140" class="svg-ods9">
              <circle cx="70" cy="70" r="70" fill="#FD6925" />
              <foreignObject x="10" y="23" width="120" height="120">
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <div
                    class="map-radar-card-text"
                    style="
                      display: flex;
                      flex-direction: row;
                      height: 30%;
                      width: 100%;
                    "
                  >
                    <p
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-items: center;
                        justify-content: flex-end;
                        padding: 0vh 0vw;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-size: 30px;
                        width: 30%;
                        font-weight: bold;
                        font-size: 28px;
                      "
                    >
                      9
                    </p>
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        padding: 2px 5px 2px 5px;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-weight: bold;
                        font-size: 10px;
                        text-transform: uppercase;
                        width: 70%;
                      "
                      >{{ $t("map.ods.ods9.name") }}</span
                    >
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      justify-content: center;
                      height: 80%;
                    "
                  >
                    <img style="width: 50%" src="./../images/ods-icon9.svg" />
                  </div>
                </div>
              </foreignObject>
            </svg>
            <svg width="140" height="140" class="svg-ods7">
              <circle cx="70" cy="70" r="70" fill="#FCC30B" />
              <foreignObject x="10" y="20" width="120" height="120">
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <div
                    class="map-radar-card-text"
                    style="
                      display: flex;
                      flex-direction: row;
                      height: 30%;
                      width: 100%;
                    "
                  >
                    <p
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-items: center;
                        justify-content: flex-end;
                        padding: 0vh 0vw;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-size: 30px;
                        width: 30%;
                        font-weight: bold;
                        font-size: 28px;
                      "
                    >
                      7
                    </p>
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        padding: 2px 5px 2px 5px;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-weight: bold;
                        font-size: 10px;
                        text-transform: uppercase;
                        width: 70%;
                      "
                      >{{ $t("map.ods.ods7.name") }}</span
                    >
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      justify-content: center;
                      height: 80%;
                    "
                  >
                    <img style="width: 50%" src="./../images/ods-icon7.svg" />
                  </div>
                </div>
              </foreignObject>
            </svg>
          </div>

          <div class="vi-first-div">
            <h1>
              <b>SEALL Intelligence:</b>
              {{ $t("landing.texts_background.title") }}
            </h1>
            <h4>{{ $t("landing.texts_background.subtitle") }}</h4>
            <router-link to="/presentation" class="links-header">
              <button type="button">
                {{ $t("landing.texts_background.button") }}
              </button>
            </router-link>
          </div>
          <div class="vi-first-svg"></div>
        </div>
        <img src="./../images/barra_ods.svg" alt="Barra" style="width: 100%" />
      </section>

      <section class="vi-section-second" id="vi-section-second">
        <div class="vi-second-column1">
          <div class="vi-second-column1-p">
            <p class="vi-second-p1" style="color: #533a8b">
              {{ $t("landing.texts_aside.title") }}
            </p>
            <p class="vi-second-p2" style="color: #36373b">
              <span style="color: #533a8b">SEALL Intelligence</span>
              {{ $t("landing.texts_aside.text") }}
            </p>
            <p class="vi-second-p3" style="color: #533a8b">
              {{ $t("landing.texts_aside.subtile") }}
            </p>
          </div>

          <div class="vi-second-column1-button">
            <router-link to="/presentation" class="links-header">
              <button type="button" class="vi-second-button">
                {{ $t("landing.texts_aside.button") }}
              </button>
            </router-link>
          </div>
        </div>
        <div class="vi-second-column2">
          <h3>{{ $t("landing.texts_cards.title") }}</h3>
          <div>
            <img src="./../images/data-icon1.svg" alt />
            <p>{{ $t("landing.texts_cards.card1") }}</p>
          </div>
          <div>
            <img src="./../images/data-icon2.svg" alt />
            <p>{{ $t("landing.texts_cards.card2") }}</p>
          </div>
          <div>
            <img src="./../images/data-icon3.svg" alt />
            <p>{{ $t("landing.texts_cards.card3") }}</p>
          </div>
          <div>
            <img src="./../images/data-icon4.svg" alt />
            <p>{{ $t("landing.texts_cards.card4") }}</p>
          </div>
          <div>
            <img src="./../images/data-icon5.svg" alt />
            <p>{{ $t("landing.texts_cards.card5") }}</p>
          </div>
          <div>
            <img src="./../images/data-icon6.svg" alt />
            <p>{{ $t("landing.texts_cards.card6") }}</p>
          </div>
          <div>
            <img src="./../images/data-icon7.svg" alt />
            <p>{{ $t("landing.texts_cards.card7") }}</p>
          </div>
          <div>
            <img src="./../images/data-icon8.svg" alt />
            <p>{{ $t("landing.texts_cards.card8") }}</p>
          </div>
          <div>
            <img src="./../images/data-icon9.svg" alt />
            <p>{{ $t("landing.texts_cards.card9") }}</p>
          </div>
        </div>
      </section>

      <section class="vi-section-fourth" id="vi-section-fourth">
        <h3>{{ $t("landing.texts_box.title") }}</h3>

        <router-link to="/presentation" class="links-header">
          <button type="button">{{ $t("landing.texts_box.button") }}</button>
        </router-link>
      </section>

      <section class="vi-section-sixth" id="vi-section-sixth">
        <h3>{{ $t("landing.texts_who.title") }}</h3>
        <div class="vi-sixth-first">
          <div class="vi-sixth-first-texts">
            <h4>{{ $t("landing.texts_who.subtitle") }}</h4>
            <h5>{{ $t("landing.texts_who.text") }}</h5>
          </div>
          <div>
            <iframe
              v-if="this.$i18n.locale == 'pt'"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/wVV4_X57df8?controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              v-else
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Zzk6SCjwlFw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div class="vi-sixth-second">
          <div class="vi-sixth-second-texts" id="vi-sixth-second">
            <h4>{{ $t("landing.texts_wedo.title") }}</h4>
            <h5>{{ $t("landing.texts_wedo.subtitle") }}</h5>
            <router-link to="/presentation" class="links-header">
              <button type="button" class="vi-sixth-second-button">
                {{ $t("landing.texts_wedo.button") }}
              </button>
            </router-link>
          </div>
          <div class="vi-sixth-second-device">
            <img src="./../images/device.png" alt />
          </div>
        </div>

        <div class="vi-sixth-thrid">
          <img src="./../images/samepeople.png" alt />
          <div>
            <p>{{ $t("landing.texts_people.title") }}</p>
          </div>
        </div>

        <div class="vi-sixth-fourth" id="vi-sixth-fourth">
          <p>{{ $t("landing.texts_solucion.title") }}</p>
          <iframe
            v-if="this.$i18n.locale == 'pt'"
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/Pifg3RyrgYE?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            v-else
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/UQnJZ_NH3Gk?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </section>
    </main>

    <footer>
      <img
        src="./../images/logo-seall-white.png"
        alt="Logo SEALL"
        class="img-logo"
      />
    </footer>
  </div>
</template>

<script>
export default {
  name: "landing",
  data() {
    return {
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
      current_header: "home",
    };
  },
  methods: {
    set_lang(lang) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
    },
    toggleHeader(link) {
      this.current_header = link;
    },
    validateHeader(link) {
      return { "active-header": link === this.current_header };
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: "Oswald", sans-serif;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

header {
  align-content: center;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  padding: 1.5vh 2vw;
}

header a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 0vh 1vw;
}

header a.mailto {
  background-color: #533a8b;
  border-radius: 4px;
  color: #ffffff;
  font-family: NunitoSans-Regular;
  border-style: hidden;
  padding: 1vh 2vw;
  text-decoration: none;
}

.header-div-img {
  width: 20%;
}

.active-header {
  color: #533a8b !important;
  font-weight: 700 !important;
}

.header-div-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 75%;
}

.header-div-langs {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0vh 0.5vw;
  width: 5%;
}

.link-header-home {
  color: #533a8b;
}

.links-header {
  color: #cad5e5;
}

.link-header-home {
  color: #533a8b;
}

.links-header {
  color: #cad5e5;
}

.vi-section-first {
  background-color: #ffffff;
}

.vi-first-background {
  background-image: url("./../images/background.png");
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.main-login-svgs-create {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}

.svg-ods1 {
  position: absolute;
  padding: 0vh 0vw 0vh 65vw;
}

.svg-ods15 {
  position: absolute;
  padding: 0vh 0vw 0vh 87vw;
}

.svg-ods6 {
  position: absolute;
  padding: 75vh 0vw 0vh 40vw;
  z-index: 49;
}

.svg-ods3 {
  position: absolute;
  padding: 85vh 0vw 0vh 65vw;
  z-index: 49;
}

.svg-ods9 {
  position: absolute;
  padding: 55vh 0vw 0vh 85vw;
  z-index: 49;
}

.svg-ods7 {
  position: absolute;
  padding: 80vh 0vw 0vh 90vw;
  z-index: 49;
}

.vi-first-div {
  padding: 10vh 0vw 10vh 5vw;
  width: 40vw;
  height: 50vh;
}

.vi-first-svg {
  width: 60vw;
  height: 100%;
}

.vi-section-first h1 {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 5vh;
  font-weight: normal;
}

.vi-section-first h4 {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 3vh;
  font-weight: normal;
}

.vi-section-first img {
  padding: 0px;
  margin: 0px;
  width: auto;
}

.vi-section-first button {
  position: absolute;
  z-index: 50;
  background: transparent;
  border-color: #ffffff;
  border-radius: 4px;
  border-style: solid;
  color: #ffffff;
  font-family: NunitoSans-Regular;
  font-size: 3vh;
  padding: 1vh 4vw;
}

.vi-section-second {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  padding-bottom: 0vh;
}

.vi-section-second p {
  margin: 0px;
}

.vi-second-column1 {
  background-color: #e9e9e9;
  border-radius: 0px 220px 0px 0px;
  display: flex;
  flex-direction: column;
  width: 40%;
}

.vi-second-column1-p {
  height: auto;
  padding: 15vh 7vw 5vh 7vw;
}

.vi-second-p1 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: bold;
}

.vi-second-p2 {
  font-family: Nunito-Regular;
  font-size: 18px;
  padding-top: 15%;
}

.vi-second-p3 {
  font-family: "Roboto", sans-serif;
  font-size: 29px;
  font-weight: bold;
  padding-top: 15%;
}

.vi-second-column1-button {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #533a8b;
  justify-content: center;
  justify-content: center;
  height: 40%;
}

.vi-second-button {
  border: 1px solid #533a8b;
  border-radius: 4px;
  background: #533a8b;
  border-color: #ffffff;
  border-radius: 4px;
  color: #ffffff;
  font-family: NunitoSans-Regular;
  font-weight: bold;
  padding: 8px 16px;
}

.vi-second-column2 {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 10vh 2vw;
  width: 60%;
}

.vi-second-column2 h3 {
  color: #9d9d9c;
  font-family: "Roboto", sans-serif;
  margin: 0px;
  padding: 0vh 12vw 3vh 8vw;
  text-align: center;
}

.vi-second-column2 div {
  align-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2),
    0px 6px 10px rgba(112, 119, 128, 0.14),
    0px 1px 18px rgba(112, 119, 128, 0.12);
  display: flex;
  flex-direction: row;
  margin: 15px;
  padding: 15px;
  width: 80%;
}

.vi-second-column2 img {
  padding: 0% 10%;
}

.vi-second-column2 p {
  background-color: #ffffff;
  font-family: Nunito-Regular;
  width: 60%;
}

.vi-section-third {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  padding: 5% 10%;
}

.vi-third-column2 {
  padding-left: 10%;
}

.vi-third-column2 p {
  color: #415066;
  font-family: "Roboto", sans-serif;
  font-size: 29px;
  margin: 0px;
}

.vi-third-column2 span {
  color: #36373b;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 18px;
}

.vi-section-fourth {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: 0vh 0vw;
  padding: 10vh 15vw 20vh;
  background-color: #533a8b;
}

.vi-section-fourth h3 {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: normal;
  text-align: center;
  padding: 0vh 0vw 10vh;
}

.vi-section-fourth button {
  background-color: #533a8b;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding: 1vh 10vw;
}

.vi-section-fiveth {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  width: 100%;
}

.vi-fiveth-first-line {
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
}

.vi-fiveth-first-card {
  align-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2),
    0px 6px 10px rgba(112, 119, 128, 0.14),
    0px 1px 18px rgba(112, 119, 128, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 15px 30px;
  padding: 15px 50px;
  text-align: center;
  width: 25%;
}

.vi-fiveth-first h3 {
  align-content: center;
  color: #36373b;
  font-family: "Roboto", sans-serif;
  font-size: 29px;
  font-weight: normal;
  padding: 2vh 25vw 0vh;
  text-align: center;
}

.vi-fiveth-first p {
  color: #533a8b;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.vi-fiveth-first img {
  width: 40%;
}

.vi-fiveth-second {
  padding-top: 10vh;
  text-align: center;
  width: 40%;
}

.vi-fiveth-second h3 {
  color: #533a8b;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.vi-fiveth-second span {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.vi-fiveth-second p {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.vi-fiveth-second-line {
  display: flex;
  flex-direction: row;
}

.vi-fiveth-second-card {
  align-content: center;
  align-items: center;
  background-color: #f7f9fa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 15px;
  width: 50%;
}

.vi-fiveth-second-button {
  align-content: center;
  background-color: #533a8b;
  border: 1px solid #533a8b;
  border-radius: 4px;
  color: #ffffff;
  font-family: NunitoSans-Regular;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  padding: 8px 16px;
  width: 100%;
}

.vi-fiveth-third {
  display: flex;
  flex-direction: column;
  padding: 20vh 0vw 10vh;
  width: 50%;
}

.vi-fiveth-third h3 {
  color: #533a8b;
  font-family: "Roboto", sans-serif;
  font-size: 29px;
  font-weight: normal;
  margin: 0px;
  padding: 0px 25px;
  text-align: center;
}

.vi-fiveth-third p {
  color: #36373b;
  font-size: 18px;
  text-align: center;
}

.vi-fiveth-third-line {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.vi-fiveth-third-line circle {
  padding: 0px;
  margin: 0px;
  width: 10%;
}

.vi-fiveth-third-line p {
  margin: 0px;
  padding: 0px;
  color: #36373b;
  font-family: "Roboto", sans-serif;
  font-size: 29px;
  text-align: start;
  width: 80%;
}

.vi-fiveth-fourth {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  background-color: #533a8b;
  padding: 50px 0px;
  width: 100%;
}

.vi-fiveth-fourth button {
  align-content: center;
  background-color: #533a8b;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  padding: 8px 150px;
}

.vi-section-sixth {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0vh 0vw 0vh 0vw;
  width: 100%;
}

.vi-section-sixth h3 {
  color: #533a8b;
  font-family: "Roboto", sans-serif;
  font-size: 46px;
  font-weight: bold;
  text-align: center;
  width: 50vw;
}

.vi-sixth-first {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0vh 5vw 0vh 0vw;
}

.vi-sixth-first-texts {
  display: flex;
  flex-direction: column;
  background-color: #f7f9fa;
  padding: 1vh 10vw;
}

.vi-sixth-first h4 {
  color: #36373b;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: normal;
}

.vi-sixth-first h5 {
  color: #f99c1c;
  font-family: "Roboto", sans-serif;
  font-size: 29px;
  font-weight: bold;
}

.vi-sixth-first img {
  box-shadow: 0px 8.18495px 11.1613px -5.2086px rgba(112, 119, 128, 0.2),
    0px 17.8581px 28.2753px 2.23226px rgba(112, 119, 128, 0.14),
    0px 6.69677px 34.228px 5.95269px rgba(112, 119, 128, 0.12);
}

.vi-sixth-second {
  display: flex;
  flex-direction: row;
}

.vi-sixth-second-device {
  background-image: url("./../images/back_elipse.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 30vh;
  width: 50%;
}

.vi-sixth-second-texts {
  display: flex;
  flex-direction: column;
  padding: 30vh 5vw 5vh 5vw;
  width: 50%;
}

.vi-sixth-second h4 {
  margin: 0px 0px 30px 0px;
  color: #687d9b;
  font-family: "Roboto", sans-serif;
  font-size: 29px;
  font-weight: normal;
}

.vi-sixth-second h5 {
  margin: 0px 0px 30px 0px;
  color: #36373b;
  font-family: "Roboto", sans-serif;
  font-size: 29px;
  font-weight: normal;
}

.vi-sixth-second-button {
  align-content: center;
  background-color: #533a8b;
  border: 1px solid #533a8b;
  border-radius: 4px;
  color: #ffffff;
  font-family: NunitoSans-Regular;
  font-weight: bold;
  font-size: 20px;
  justify-content: center;
  padding: 8px 16px;
  width: 100%;
}

.vi-sixth-second img {
  height: 80%;
}

.vi-sixth-thrid {
  display: flex;
  flex-direction: row;
}

.vi-sixth-thrid div {
  display: flex;
  align-items: center;
  background-color: #533a8b;
}

.vi-sixth-thrid p {
  padding: 0vh 10vw;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 29px;
}

.vi-sixth-fourth {
  align-items: center;
  margin: 15vh 10vw;
  display: flex;
  flex-direction: row;
  width: 90%;
}

.vi-sixth-fourth p {
  padding: 0vh 5vw 5vh 5vw;
  color: #36373b;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  width: 50%;
}

.vi-sixth-fourth img {
  box-shadow: 0px 8.18495px 11.1613px -5.2086px rgba(112, 119, 128, 0.2),
    0px 17.8581px 28.2753px 2.23226px rgba(112, 119, 128, 0.14),
    0px 6.69677px 34.228px 5.95269px rgba(112, 119, 128, 0.12);
}

.vi-section-seventh {
  background-color: #f7f9fa;
  padding: 15vh 10vw;
  display: flex;
  flex-direction: row;
}

.vi-seventh div {
  padding: 25px;
}

.vi-seventh-title {
  width: 20%;
}

.vi-seventh-title h4 {
  margin: 0px;
  padding: 0px;
  color: #533a8b;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 29px;
}

.vi-seventh-box {
  width: 70%;
}

.vi-seventh-box details {
  border-radius: 8px;
  box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2),
    0px 6px 10px rgba(112, 119, 128, 0.14),
    0px 1px 18px rgba(112, 119, 128, 0.12);
  padding: 25px;
}

.vi-seventh-box summary {
  color: #533a8b;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 18px;
}

.vi-seventh-box p {
  color: #9d9d9c;
  font-family: Nunito-Regular;
  font-size: 18px;
  width: 100%;
}

footer {
  background-color: #533a8b;
}

footer img {
  padding: 0px 0px 0px 50px;
}

@media screen and (max-width: 1025px) {
  html,
  body {
    border: 0vh 0vw;
    height: 100%;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  header {
    margin: 0vh 0vw;
    padding: 1vh 2vw;
  }

  header a {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: bold;
    padding: 0vh 1vw 0vh 0vw;
  }

  header img {
    width: 90%;
  }

  section {
    margin: 0vh 0vw;
    padding: 0vh 0vw;
  }

  .header-div-img {
    padding-left: 0vw;
    width: 20%;
  }

  .header-div-links {
    justify-content: center;
    width: 75%;
  }

  .header-div-links a {
    padding: 0.5vh 1vw;
  }

  .header-div-links button {
    margin: 0.5vh 1vw;
  }

  .header-div-langs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0vh 0vw;
    margin: 0vh 0vw;
    height: 10vh;
  }

  header a {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: bold;
    padding: 0vh 1vw;
  }

  .vi-first-background {
    background-image: url("./../images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 20vh;
    display: flex;
    flex-direction: row;
    z-index: 1;
  }

  .vi-first-div {
    z-index: 1;
    margin: 0vh 0vw;
    padding: 5vh 5vw;
    width: 100vw;
  }

  .main-login-svgs-create svg {
    width: 0vw;
    padding: 0vh 0vw;
    margin: 0vh 0vw;
  }

  .vi-section-first button {
    background: transparent;
    border-color: #ffffff;
    border-radius: 4px;
    border-style: solid;
    color: #ffffff;
    font-family: NunitoSans-Regular;
    font-size: 1.5vh;
    padding: 1vh 4vw;
  }

  .vi-section-first img {
    margin: 0vh 0vw;
    padding: 0vh 0vw;
  }

  .vi-section-first h1 {
    font-size: 3vh;
  }

  .vi-section-first h4 {
    font-size: 2vh;
  }

  .vi-section-second {
    display: flex;
    flex-direction: column;
  }

  .vi-second-column1 {
    width: 100%;
  }

  .vi-second-column1-p {
    height: auto;
    padding: 15vh 5vw;
  }

  .vi-second-column2 {
    padding: 5vh 0vw;
    width: 100%;
  }

  .vi-second-p1 {
    font-size: 22px;
    padding: 2vh 0vw;
  }

  .vi-second-p2 {
    font-size: 20px;
    padding: 2vh 0vw;
  }

  .vi-second-p3 {
    font-size: 24px;
    padding: 2vh 0vw;
  }

  .vi-second-column1-button {
    padding: 15vh 0vw;
  }

  .vi-second-column2 h3 {
    font-size: 15px;
  }

  .vi-third-column1 img {
    width: 150%;
  }

  .vi-third-column2 p {
    font-size: 15px;
  }

  .vi-third-column2 span {
    font-size: 12px;
  }

  .vi-section-fourth h3 {
    font-size: 20px;
  }

  .vi-fiveth-first h3 {
    font-size: 20px;
  }

  .vi-fiveth-first p {
    font-size: 15px;
  }

  .vi-fiveth-first-line {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }

  .vi-fiveth-first-card {
    width: 50%;
  }

  .vi-fiveth-first img {
    width: 50%;
  }

  .vi-fiveth-second {
    align-content: center;
    align-items: center;
    padding: 10vh 0vw;
    width: 90%;
  }

  .vi-fiveth-second-line {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .vi-fiveth-third {
    display: flex;
    flex-direction: column;
    padding: 10vh 0vw;
    width: 90%;
  }

  .vi-fiveth-third-line p {
    font-size: 18px;
  }

  .vi-fiveth-third h3 {
    font-size: 20px;
  }

  .vi-fiveth-fourth button {
    padding: 8px 50px;
  }

  .vi-section-sixth h3 {
    font-size: 26px;
  }

  .vi-sixth-first {
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
  }

  .vi-sixth-first-texts {
    margin: 0vh 0vw;
    padding: 0vh 5vw;
  }

  .vi-sixth-first h4 {
    font-size: 15px;
  }

  .vi-sixth-first h5 {
    font-size: 20px;
  }

  .vi-sixth-first img {
    padding: 0vh 0vw;
    margin: 0vh 0vw;
    width: 90%;
  }

  .vi-sixth-second {
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .vi-sixth-second-device {
    background-image: url("#ffff");
    padding: 0px;
    margin: 0px;
  }

  .vi-sixth-second-texts {
    align-items: center;
    padding: 5vh 0vw 0vh 0vw;
    width: 90%;
  }

  .vi-sixth-second h4 {
    padding: 5vh 0vw 0vh 0vw;
    margin: 0px;
    font-size: 20px;
  }

  .vi-sixth-second h5 {
    margin: 0px;
    padding: 5vh 0vw 5vh 0vw;
    font-size: 20px;
  }

  .vi-sixth-second-button {
    padding: 8px 16px;
    width: 100%;
  }

  .vi-sixth-second img {
    height: 0px;
    width: 0px;
    padding-bottom: 10vh;
  }

  .vi-sixth-thrid {
    display: flex;
    flex-direction: column-reverse;
  }

  .vi-sixth-thrid img {
    width: 100%;
  }

  .vi-sixth-thrid p {
    font-size: 20px;
  }

  .vi-sixth-fourth {
    display: flex;
    flex-direction: column;
    padding: 5vh 0vw;
    margin: 0vh 0vw;
    width: 90%;
  }

  .vi-sixth-fourth p {
    padding: 0vh 0vw 5vh 0vw;
    width: 100%;
    text-align: center;
  }

  .vi-sixth-fourth img {
    width: 90%;
  }

  .vi-sixth-banner img {
    height: 0%;
    width: 0%;
    padding: 0vh 0vw;
  }

  .vi-section-seventh {
    background-color: #f7f9fa;
    padding: 10vh 10vw;
    display: flex;
    flex-direction: column;
  }

  .vi-seventh-title {
    text-align: center;
    width: 100%;
  }

  .vi-seventh-title h4 {
    font-size: 20px;
  }

  .vi-seventh-box {
    padding-top: 5vh;
    width: 100%;
  }

  .vi-seventh-box summary {
    font-size: 15px;
  }

  .vi-seventh-box p {
    font-size: 15px;
  }

  .vi-section-sixth iframe {
    width: 100%;
  }
}
</style>