export default {
    main: {
        message: 'Ola mundo',
        required_field: 'Campo obrigatório',
        form_invalid: 'Preencha todos os campos obrigatórios',
        logout: 'Sair da Plataforma',
        error_main: 'Alguma falha aconteceu',
        fill_organization: 'Por Favor, crie sua organização'
    },
    landing: {
        header: {
            home: 'HOME',
            who: 'QUEM SOMOS',
            we_do: 'O QUE FAZEMOS',
            for: 'PARA QUEM',
            solucion: 'NOSSA SOLUÇÃO',
            contact_us: 'FALE CONOSCO'
        },
        texts_background: {
            title: 'uma plataforma para a gestão inteligente da sua jornada de impacto.',
            subtitle:
                'Conheça a nova solução da SEALL que apoia organizações, empreendedores sociais e negócios de impacto a tomarem decisões baseadas em dados que importam.',
            button: 'QUERO EXPERIMENTAR!'
        },
        texts_aside: {
            title:
                'Atualmente, quem mensura o impacto gerado pela sua organização, está um passo à frente.',
            text:
                'nasceu para suprir a necessidade das organizações de fazer uma gestão de impacto responsável e transparente. Através de dados confiáveis, líderes conscientes podem tomar decisões cada vez mais assertivas que gerem maior valor aos seus públicos estratégicos.',
            subtile:
                'O objetivo é que líderes consigam gerir dados sobre a sua organização de forma transparente e descomplicada.',
            button: 'QUERO EXPERIMENTAR!'
        },
        texts_cards: {
            title: 'Essa plataforma gratuita é para quem quer ter em mãos informações claras para:',
            card1:
                'Gerar dados a partir de uma metodologia baseada em padrões internacionalmente reconhecidos',
            card2:
                'Ter clareza e dados confiáveis para tomar decisões mais efetivas e fundamentar a sua estratégia',
            card3:
                'Apresentar dados relevantes para parceiros e possíveis investidores e /ou clientes',
            card4: 'Monitorar e melhorar os seus processos de maneira contínua',
            card5: 'Manter seus dados atualizados',
            card6: 'Checar o cumprimento de metas',
            card7: 'Unificar a visão do impacto',
            card8: 'Escalar o impacto de sua organização',
            card9: 'Medir o impacto de forma transparente'
        },
        texts_box: {
            title:
                'Para que sua organização se fortaleça e tenha a visibilidade completa do impacto que gera no mundo, facilitamos o seu processo de mensuração. Colocamos a nossa metodologia à sua disposição para a gestão efetiva e inovadora do impacto de sua organização.',
            button: 'QUERO EXPERIMENTAR'
        },
        texts_who: {
            title: 'Quem faz a SEALL Intelligence?',
            subtitle:
                'A SEALL é uma startup de impacto que recebeu investimento do Grupo Houer em 2020. Juntos reforçamos o nosso desejo de guiar organizações na criação de um mundo melhor.',
            text: 'Nossa missão é ajudar você a fortalecer o legado da sua organização.'
        },
        texts_wedo: {
            title: 'O que fazemos?',
            subtitle:
                'Entre nossos serviços, disponibilizamos a SEALL Intelligence, uma plataforma exclusiva de gestão do impacto.',
            button: 'QUERO EXPERIMENTAR'
        },
        texts_people: {
            title:
                'Contribuímos com líderes e organizações que lidam com a complexidade de comprovar o impacto das suas atividades.'
        },
        texts_solucion: {
            title:
                'Facilitamos o entendimento de matrizes globais para democratizar e otimizar a gestão do impacto socioambiental e econômico de projetos, iniciativas e organizações.'
        }
    },
    login: {
        main: {
            create: 'Crie sua conta',
            login: 'Acesse sua conta ',
            email: 'Email',
            pass: 'Senha',
            placeh_email: '',
            placeh_pass: '',
            text: 'Baseado na Teoria da Mudança',
            email_invalid: 'E-mail inválido',
            password_invalid:
                'A senha deve conter um caracter minúsculo, um caracter maiúsuclo, um número, um caracter especial e de tamanho mínimo de 8 caracteres no total. Ex: Aa12345*',
            error_login: 'E-mail ou senha inválido'
        },
        create: {
            term:
                'Criar uma conta significa que você concorda com nossos Termos de Serviço, Política e Privacidade.',
            button: 'Criar meu mapa da transformação',
            message: 'Por segurança, insira abaixo o código que enviamos para seu e-mail',
            placeh_code: 'Código de ativação',
            resend: 'Reenviar código',
            confirm: 'Confirmar código',
            back: 'Voltar',
            use_term: 'Termos de uso',
            button_close: 'Fechar',
            terms:
                'Estou ciente que nos termos da Lei no 13.709, de 14 de agosto de 2018, meus dados serão tratados e mantidos nesta base de dados com a finalidade de cadastrar o titular como usuário externo em sistema informatizado desta plataforma . Tomei conhecimento que tenho direito a obter da empresa SEALL, a qualquer momento e mediante requerimento, todas as informações referente a meus dados e seu respetivo tratamento. Estou ciente e de acordo que meus dados poderão ser cedido a parceiros para fins de anúncios e conteúdos personalizados, medição de anúncios e conteúdos, perspetivas sobre o público e desenvolvimento de produtos e serviços.'
        },
        login: {
            link: 'Esqueci minha senha',
            button: 'Acessar minha conta'
        },
        forgot: {
            title: 'Recuperação de Senha',
            subtitle:
                'Ao solicitar a recuperação de senha, será enviado um código verificador para o e-mail informado',
            button_next: 'Solicitar',
            verification_code: 'Código de verificação',
            email: 'E-mail',
            new_password: 'Nova senha',
            recover_password: 'Recuperar senha',
            message_success: 'Senha alterada com sucesso!',
            message_warn_two:
                'Algum error aconteceu ao alterar a senha, o código de verificação pode ser inválido.',
            message_warn: 'Algum error aconteceu, tente novamente mais tarde.'
        }
    },
    presentation: {
        title: 'Inicie sua jornada de impacto',
        subtile:
            'Em algumas etapas você vai conseguir entender, medir e gerir o impacto de sua empresa no mundo, entenda',
        button_top: 'Quero ser um líder de impacto',
        button_bottom: 'Quero iniciar minha jornada agora',
        cards: {
            card1: {
                title: 'Mapa da transformação',
                description:
                    'Usamos a abordagem da Teoria da Mudança para te ajudar a refletir e comunicar as primeiras visões do seu impacto, incluindo um primeiro alinhamento à Agenda 2030 com o nosso Radar de Alinhamento Estratégico aos ODS.',
                type: 'FREE'
            },
            card2: {
                title: 'Percepcão dos Públicos',
                description:
                    'Você pode coletar percepções de impacto dos seus stakeholders internos e externos e selecionar os protocolos mais relevantes para a sua organização.',
                type: 'BASIC'
            },
            card3: {
                title: 'Matriz de Materialidade',
                description:
                    'Torne material o que é intangível. Com a materialidade nas mãos você terá clareza das principais temáticas de impacto que precisará trabalhar na sua organização. A sua Matriz estará 100% alinhada e comunicada em relação à Agenda 2030, com a nossa metodologia exclusiva.',
                type: 'BASIC'
            },
            card4: {
                title: 'Matriz de Indicadores',
                description:
                    'Selecione os indicadores que sua organização já monitora e tenha clareza de quais ainda faltam ou deveriam ser incluídos para capturar seu impacto. Nosso sistema gerar uma priorização automática dos indicadores necessários a partir da sua Matriz de Materialidade, desenvolvida na etapa anterior.',
                type: 'INTERMEDIÁRIO'
            },
            card5: {
                title: 'Gestão do Impacto',
                description:
                    'Aqui você poderá monitorar o dia-a-dia da mudança que você faz no mundo e se tornar um líder de impacto! Nosso sistema permite uma gestão efetiva de cada indicador e ações relacionadas.',
                type: 'INTERMEDIÁRIO'
            },
            card6: {
                title: 'Resultados de Impacto',
                description:
                    'Tenha acesso a um relatório compartilhável com os principais dados e resultados do seu impacto para uma comunicação mais efetiva com os seus stakeholders, incluindo investidores e instituições financiadoras.',
                type: 'INTERMEDIÁRIO'
            }
        }
    },
    questions: {
        title: 'Inicie seu mapa da transformação ',
        subtitle:
            'Para darmos início, precisamos de algumas informações que irão nortear nossa jornada. Vamos começar?',
        info:
            'Com base na sua organização ou iniciativa, é fundamental que você preencha com cuidado e atenção todos os campos. São essas informações que vão nos guiar em toda a jornada e irão embasar o seu mapa da transformação.',
        button_top: 'Quero ser um líder de impacto',
        button_bottom: 'Salvar e continuar',
        save: 'Salvar',
        how_to_use:
            'Aperte enter para adicionar um item e backspace para remover o último item inserido',
        cards: {
            card1: 'Mapa da transformação',
            card2: 'Percepção dos Públicos',
            card3: 'Matriz de Materialidade',
            card4: 'Matriz de Indicadores',
            card5: 'Gestão de Impacto',
            card6: 'Resultado do Impacto'
        },
        index: {
            tipo: 'Tipo',
            nome: 'Nome',
            missao: 'Missão',
            paraquem: 'Para quem',
            problema: 'Problema',
            solucao: 'Solução',
            atividades: 'Atividades',
            efeitos: 'Efeitos mensuráveis',
            resultados: 'Resultados',
            impacto: 'Impacto'
        },
        questions: {
            question: {
                question: 'Nesta etapa estaremos tratando de:',
                option1: 'Sua organização',
                option2: 'Uma iniciativa da organização'
            },
            question0: {
                question: 'Nome da iniciativa',
                placeholder: 'Como deseja chamar sua iniciativa?'
            },
            question1: {
                question: 'Missão',
                placeholder: 'Qual a missão da sua iniciativa?'
            },
            question2: {
                question: 'Para quem',
                placeholder: 'Quem são as pessoas impactadas e/ou beneficiadas por ela?',
                items: 'Professores, Alunos, Escolas na cidade de Campinas, SP'
            },
            question3: {
                question: 'Entendimento do Problema',
                placeholder:
                    'Qual o problema que ele resolve? Se a sua iniciativa não existisse no mundo, o que faltaria?',
                items: 'Falta de estrutura, Falta de tempo'
            },
            question4: {
                question: 'Soluções',
                placeholder: 'Quais soluções a sua iniciativa entrega?',
                items: 'Aulas EAD, Eventos presenciais, Conferências, Produtos'
            },
            question5: {
                question: 'Atividades',
                placeholder:
                    'Quais as atividades-chave mais importantes para que a(as) solução(ões) seja(m) entregue(s)?',
                items: 'Capacitação de professores, Formação de alunos'
            },
            question6: {
                question: 'Efeitos mensuráveis',
                placeholder: 'Quais os efeitos mensuráveis/quantificáveis você percebe?',
                items: 'Número de professores capacitados, Taxa de presença, NPS'
            },
            question7: {
                question: 'Resultados',
                placeholder:
                    'Qual(ais) o(s) resultado(s) pode ser visto, após experimentarem sua(s) solução(ões)?',
                items: 'Número de professores capacitados'
            },
            question8: {
                question: 'Impacto',
                placeholder: 'Qual o impacto a longo prazo gerado pela sua iniciativa?',
                items:
                    'Mapeamento de novas tecnologias, Desenvolvimento local, Fortalecimento do comércio, Redução da mortalidade infantil'
            }
        },
        modal: {
            title: 'Parabéns!',
            subtitle: 'Você iniciou a sua jornada da transformação!',
            info:
                'Precisamos te conhecer um pouco mais para conseguirmos te auxiliar nessa jornada. Por favor, preencha agora alguns dados sobre a sua organização.',
            button_go: 'Avançar',
            button_back: 'Voltar'
        }
    },
    organization: {
        title: 'Perfil da organização',
        subtitle:
            'Preencha usando os dados da sua organização. Eles serão usados apenas para garantir que seu mapa seja exclusivo e customizado',
        button_top: 'Quero ser um líder de impacto',
        button_bottom: 'Salvar e continuar',
        cards: {
            card1: 'Mapa da transformação',
            card2: 'Percepção dos Públicos',
            card3: 'Matriz de Materialidade',
            card4: 'Matriz de Indicadores',
            card5: 'Gestão de Impacto',
            card6: 'Resultado do Impacto'
        },
        itens: {
            avatar: 'Adicionar avatar',
            name: 'Nome da organização',
            name_placeholder: 'Escreva aqui',
            cnpj: 'CNPJ',
            cnpj_placeholder: 'Escreva aqui',
            segment: 'Área/Segmento (principal)',
            segment_placeholder: 'Escreva aqui',
            type: 'Tipo de Organização',
            type_placeholder: 'Selecione uma opção',
            number: 'Número de funcionários / beneficiários',
            number_placeholder: 'Escreva aqui',
            city: 'Cidade',
            city_placeholder: 'Escreva aqui',
            state: 'UF',
            state_placeholder: 'Escreva aqui',
            country: 'País',
            country_placeholder: 'Escreva aqui'
        },
        types: {
            public: 'Pública',
            private: 'Privada',
            third_sector: 'Terceiro Setor',
            impact_business: 'Negócio de Impacto'
        },
        areas: {
            A: {
                value: 'A',
                text: 'AGRICULTURA, PECUÁRIA, PRODUÇÃO FLORESTAL, PESCA E AQÜICULTURA'
            },
            B: { value: 'B', text: 'INDÚSTRIAS EXTRATIVAS' },
            C: { value: 'C', text: 'INDÚSTRIAS DE TRANSFORMAÇÃO' },
            D: { value: 'D', text: 'ELETRICIDADE E GÁS' },
            E: {
                value: 'E',
                text: 'ÁGUA, ESGOTO, ATIVIDADES DE GESTÃO DE RESÍDUOS E DESCONTAMINAÇÃO'
            },
            F: { value: 'F', text: 'CONSTRUÇÃO' },
            G: { value: 'G', text: 'COMÉRCIO; REPARAÇÃO DE VEÍCULOS AUTOMOTORES E MOTOCICLETAS' },
            H: { value: 'H', text: 'TRANSPORTE, ARMAZENAGEM E CORREIO' },
            I: { value: 'I', text: 'ALOJAMENTO E ALIMENTAÇÃO' },
            J: { value: 'J', text: 'INFORMAÇÃO E COMUNICAÇÃO' },
            K: { value: 'K', text: 'ATIVIDADES FINANCEIRAS, DE SEGUROS E SERVIÇOS RELACIONADOS' },
            L: { value: 'L', text: 'ATIVIDADES IMOBILIÁRIAS' },
            M: { value: 'M', text: 'ATIVIDADES PROFISSIONAIS, CIENTÍFICAS E TÉCNICAS' },
            N: { value: 'N', text: 'ATIVIDADES ADMINISTRATIVAS E SERVIÇOS COMPLEMENTARES' },
            O: { value: 'O', text: 'ADMINISTRAÇÃO PÚBLICA, DEFESA E SEGURIDADE SOCIAL' },
            P: { value: 'P', text: 'EDUCAÇÃO' },
            Q: { value: 'Q', text: 'SAÚDE HUMANA E SERVIÇOS SOCIAIS' },
            R: { value: 'R', text: 'ARTES, CULTURA, ESPORTE E RECREAÇÃO' },
            S: { value: 'S', text: 'OUTRAS ATIVIDADES DE SERVIÇOS' },
            T: { value: 'T', text: 'SERVIÇOS DOMÉSTICOS' },
            U: {
                value: 'U',
                text: 'ORGANISMOS INTERNACIONAIS E OUTRAS INSTITUIÇÕES EXTRATERRITORIAIS'
            }
        }
    },
    legacy: {
        title: 'Você está a poucas perguntas do seu mapa',
        subtitle:
            'Responda às perguntas a seguir. São elas que, de forma totalmente gratuita, irão nos ajudar a entender a relação das suas iniciativas com a Agenda 2030 e os Objetivos de Desenvolvimento Sustentável (ODS). Esse processo vai levar, no máximo, 10 minutos. E será só o começo da sua jornada em direção à gestão inteligente do impacto da sua organização.',
        info: 'Na sua percepção, qual é a contribuição da sua organização ou iniciativa para:',
        button_top: 'Quero ser um líder de impacto',
        button_bottom: 'Salvar e continuar',
        tooltip: 'Click nos números das perguntas para navegar entre elas.',
        scale: {
            bad: 'Nenhuma contribuição',
            good: 'Muito alta contribuição'
        },
        cards: {
            card1: 'Mapa da transformação',
            card2: 'Percepção dos Públicos',
            card3: 'Matriz de Materialidade',
            card4: 'Matriz de Indicadores',
            card5: 'Gestão de Impacto',
            card6: 'Resultado do Impacto'
        },
        questions: {
            question0:
                'Acabar com a fome, gerar acesso a alimentos seguros e de qualidade, promover e apoiar a nutrição adequada e reduzir as perdas e os desperdícios de alimentos.',
            question1:
                'Promover o bem-estar e a saúde, gerar acesso a tratamentos e medicamentos, conscientizar e reduzir as incidências e as fatalidades por doenças.',
            question2:
                'Promover e gerar acesso a serviços básicos e essenciais, como os de habitação, infraestrutura, acesso à energia, tecnologia, serviços financeiros, entre outros.',
            question3:
                'Possibilitar o acesso à educação e à qualificação, formal ou não formal, em nível básico, técnico ou superior, de forma inclusiva e capaz de promover a aprendizagem e a profissionalização, incluindo a educação para o desenvolvimento sustentável.',
            question4:
                'Apoiar e desenvolver a agricultura, de forma sustentável, resiliente e inovadora, capaz de apoiar o desenvolvimento e promover a geração de emprego e renda.',
            question5:
                'Fortalecer as tomadas de decisões responsivas, práticas de transparência e anticorrupção e gestão eficiente de organizações públicas e privadas. ',
            question6:
                'Promover e apoiar o desenvolvimento e a geração de energias limpas, com fontes renováveis e seguras, capazes de contribuir com a melhoria da eficiência energética e a mitigação das mudanças climáticas.',
            question7:
                'Promover e incentivar o desenvolvimento econômico, a geração de emprego e renda, a diversificação e a modernização das economias, além de valorizar a indústria, o empreendedorismo, os negócios locais e os micro e pequenos empresários e produtores.',
            question8:
                'Garantir e promover os direitos fundamentais, em respeito e concordância à legislação e ao desenvolvimento sustentável.',
            question9:
                'Garantir e promover o acesso ao saneamento seguro e de qualidade e a gestão responsável da água e do esgoto, contribuindo para a universalização do acesso à água potável em locais em situações de vulnerabilidade e redução dos incidentes e doenças por falta de saneamento.',
            question10:
                'Garantir e promover a igualdade de oportunidades para todas as pessoas, a igualdade de gênero, o respeito às pessoas com deficiência, o respeito a todas as diferenças, a não discriminação e a participação coletiva.',
            question11:
                'Promover ou incentivar investimentos financeiros para o desenvolvimento da infraestrutura, ciência e tecnologia, da medicina e dos mercados em prol do desenvolvimento sustentável da sociedade e das instituições.',
            question12:
                'Promover e garantir a segurança de todos e o acesso à justiça, combater todas as formas de violência, enfrentar a ocorrência de crimes e delitos em todos os níveis.',
            question13:
                'Desenvolver e apoiar ações em prol da qualidade ambiental, incentivando a redução e a mitigação da poluição da água, do ar e do solo, bem como a preservação e a manutenção da biodiversidade (fauna e flora).',
            question14:
                'Erradicar a pobreza e a vulnerabilidade, em todas as suas dimensões, para todas as pessoas, em todos os lugares.',
            question15:
                'Promover o uso responsável e sustentável dos recursos naturais, incluindo a redução dos resíduos gerados por meio de práticas de reciclagem e reúso.',
            question16:
                'Reduzir e controlar riscos sociais e ambientais, tais como desastres, acidentes, epidemias, contaminações e outros.'
        },
        modal: {
            title: 'Parabéns!',
            subtitle: 'Você está cada vez mais próximo de ser uma liderança de impacto',
            maissubtitle:
                'Você completou o cadastro, clique em continuar para ver o mapa do impacto social',
            info:
                'Dê uma nota de 1 a 5 sobre a dificuldade do questionário, sendo “1” muito fácil e “5” muito difícil.',
            button_go_mais: 'Continuar para o MAIS',
            button_go_radar: 'Gerar Radar de alinhamento da OSC',
            button_go: 'Continuar',
            button_back: 'Voltar',
            scale: {
                bad: 'Muito fácil',
                good: 'Muito difícil'
            }
        }
    },
    map: {
        title: 'INICIATIVA',
        mission: 'Missão',
        map: 'Mapa da transformação',
        map_info: 'Conecte os pontos! Vincule os fatos que possuem relação direta.',
        radar: 'Radar de Alinhamento estratégico aos ODS',
        radar_info: 'Veja como ficou mais fácil perceber como suas ações estão alinhadas aos ODS´s',
        button_top: 'Quero ser um líder de impacto',
        button_bottom: 'Quero ser um líder de impacto',
        button_share: 'Compartilhar meu mapa',
        button_download: 'Baixar meu mapa',
        button_edit: 'Alterar informações',
        button_pdf: 'Baixar map em PDF',
        mapwords: {
            para: {
                nome: 'Para',
                itens: ['Professores', 'Alunos', 'Escolas na cidade de Campinas, SP']
            },
            problemas: {
                nome: 'Que tem problemas de:',
                itens: ['Falta de estrutura', 'Falta de tempo']
            },
            propomos: {
                nome: 'Propomos soluções de',
                itens: ['Aulas EAD', 'Eventos presenciais', 'Conferências', 'Produtos']
            },
            atividades: {
                nome: 'Que as atividades chaves são:',
                itens: ['Capacitação de professores', 'Formação de alunos']
            },
            medimos: {
                nome: 'Efeitos mensuráveis:',
                itens: ['Número de professores capacitados', 'Taxa de presença', 'NPS']
            },
            resultados: {
                nome: 'Resultados em',
                itens: ['Número de professores capacitados']
            },
            solucoes: {
                nome: 'Impacto',
                itens: [
                    'Mapeamento de novas tecnologias',
                    'Desenvolvimento local',
                    'Fortalecimento do comércio',
                    'Redução da mortalidade infantil'
                ]
            }
        },
        texts: {
            click_max: 'Clique aqui e saiba mais',
            click_min: 'Clique aqui para minimizar',
            text_card: 'de Alinhamento aos ODS'
        },
        cards: {
            card1: 'Mapa da transformação',
            card2: 'Percepção dos Públicos',
            card3: 'Matriz de Materialidade',
            card4: 'Matriz de Indicadores',
            card5: 'Gestão de Impacto',
            card6: 'Resultado do Impacto'
        },
        ods: {
            ods1: {
                name: 'Erradicação da Pobreza',
                desc: 'Acabar com a pobreza em todas as suas formas, em todos os lugares',
                text:
                    'Em 2000, o mundo comprometeu-se em reduzir pela metade o número de pessoas vivendo em extrema pobreza e alcançou ganhos notáveis no desenvolvimento humano. Até 2015, a pobreza havia sido reduzida significativamente, o acesso ao ensino básico e os resultados da saúde melhoraram, bem como foram realizados progressos na promoção da igualdade de gênero e no empoderamento das mulheres e meninas. No entanto, a erradicação da pobreza extrema continua a ser um desafio, com mais de 700 milhões de pessoas vivendo, globalmente, com menos de US$ 1,90 (PPP) por dia e mais da metade da população global vivendo com menos de US$ 8,00 por dia.\n\nEm um mundo confrontado pelos crescentes desafios para o desenvolvimento, a Agenda 2030 reconhece que a erradicação da pobreza, em todas as suas formas, é o maior desafio global para atingirmos o desenvolvimento sustentável. Por isso, a grande prioridade do desenvolvimento sustentável deve ser os mais pobres e vulneráveis: ninguém será deixado para trás!'
            },
            ods2: {
                name: 'Fome Zero e Agricultura Sustentável',
                desc:
                    'Acabar com a fome, alcançar a segurança alimentar e melhoria da nutrição e promover a agricultura sustentável',
                text:
                    'Durante as duas últimas décadas, o rápido crescimento econômico e o desenvolvimento da agricultura foram responsáveis pela redução pela metade da proporção de pessoas subnutridas no mundo. Entretanto, ainda há 795 milhões de pessoas no mundo que, em 2014, viviam sob o espectro da desnutrição crônica. O ODS 2 pretende acabar com todas as formas de fome e má-nutrição até 2030, de modo a garantir que todas as pessoas - especialmente as crianças - tenham acesso suficiente a alimentos nutritivos durante todos os anos.\n\nPara alcançar este objetivo, é necessário promover práticas agrícolas sustentáveis, por meio do apoio à agricultura familiar, do acesso equitativo à terra, à tecnologia e ao mercado.'
            },
            ods3: {
                name: 'Saúde e Bem-Estar',
                desc:
                    'Assegurar uma vida saudável e promover o bem-estar para todos, em todas as idades',
                text:
                    'Desde os ODM foram registrados progressos históricos na redução da mortalidade infantil, na melhoria da saúde materna e na luta contra o HIV/Aids, a tuberculose, a malária e outras doenças. Em 15 anos, o número de pessoas infectadas pelo HIV anualmente caiu de 3,1 milhões para 2 milhões e mais de 6,2 milhões de vidas foram salvas da malária.\n\nApesar do progresso, as doenças crônicas e aquelas resultantes de desastres continuam a ser os principais fatores que contribuem para a pobreza e para a privação dos mais vulneráveis. Atualmente, 63% de todas as mortes do mundo provêm de doenças não transmissíveis, principalmente cardiovasculares, respiratórias, câncer e diabetes. Estima-se que as perdas econômicas para os países de renda média e baixa provenientes destas doenças ultrapassaram US$ 7 trilhões até 2025.\n\nOs ODS propõem metas integradas que abordam a promoção da saúde e bem estar como essenciais ao fomento das capacidades humanas.'
            },
            ods4: {
                name: 'Educação de Qualidade',
                desc:
                    'Assegurar a educação inclusiva e equitativa de qualidade, e promover oportunidades de aprendizagem ao longo da vida para todos',
                text:
                    'Desde 2000, houve enorme progresso na promoção do acesso universal à educação primária para as crianças ao redor do mundo. Para além do foco na educação básica, todos os níveis de educação estão contemplados no objetivo de desenvolvimento sustentável 4, que enxerga como fundamental a promoção de uma educação inclusiva, igualitária e baseada nos princípios de direitos humanos e desenvolvimento sustentável. A promoção da capacitação e empoderamento dos indivíduos é o centro deste objetivo, que visa ampliar as oportunidades das pessoas mais vulneráveis no caminho do desenvolvimento.'
            },
            ods5: {
                name: 'Igualdade de Gênero',
                desc: 'Alcançar a igualdade de gênero e empoderar todas as mulheres e meninas',
                text:
                    'A igualdade de gênero não é apenas um direito humano fundamental, mas a base necessária para a construção de um mundo pacífico, próspero e sustentável. O esforço de alcance do ODS 5 é transversal à toda Agenda 2030 e reflete a crescente evidência de que a igualdade de gênero tem efeitos multiplicadores no desenvolvimento sustentável.\n\nMuitos avanços em termos de assegurar melhores condições de vida a mulheres e meninas são um importante legado dos ODM. Os Objetivos de Desenvolvimento Sustentável visam intensificar estas realizações, não apenas nas áreas de saúde, educação e trabalho, mas especialmente no combate às discriminações e violências baseadas no gênero e na promoção do empoderamento de mulheres e meninas para que possam atuar enfaticamente na promoção do desenvolvimento sustentável, por meio da participação na política, na economia, e em diversas áreas de tomada de decisão.\n\nO desenvolvimento sustentável não será alcançado se as barreiras tangíveis e intangíveis que impedem o pleno desenvolvimento e exercício das capacidades de metade da população não forem eliminadas.'
            },
            ods6: {
                name: 'Água Potável e Saneamento',
                desc:
                    'Assegurar a disponibilidade e a gestão sustentável da água e saneamento para todos',
                text:
                    'A água está no centro do desenvolvimento sustentável e das suas três dimensões - ambiental, econômica e social. Os recursos hídricos, bem como os serviços a eles associados, sustentam os esforços de erradicação da pobreza, de crescimento econômico e da sustentabilidade ambiental. O acesso à água e ao saneamento importa para todos os aspectos da dignidade humana: da segurança alimentar e energética à saúde humana e ambiental.\n\nA escassez de água afeta mais de 40% da população mundial, número que deverá subir ainda mais como resultado da mudança do clima e da gestão inadequada dos recursos naturais. É possível trilhar um novo caminho que nos leve à realização deste objetivo, por meio da cooperação internacional, proteção às nascentes, rios e bacias e compartilhamento de tecnologias de tratamento de água.'
            },
            ods7: {
                name: 'Energia Acessível e Limpa',
                desc:
                    'Assegurar o acesso confiável, sustentável, moderno e a preço acessível à energia para todos',
                text:
                    'De 2000 a 2013, mais de 5% da população mundial obteve acesso à eletricidade (de 79,313% para 84,58%). Para os próximos anos a tendência é aumentar a demanda por energia barata. Contudo, combustíveis fósseis e suas emissões de gases de efeito estufa provocam mudanças drásticas no clima. Atender às necessidades da economia e proteger o meio ambiente é um dos grandes desafios para o desenvolvimento sustentável. Nesse sentido, o ODS 7 reconhece a importância e traça metas focadas na transição energética, de fontes não renováveis e poluidoras, para fontes renováveis limpas, com especial atenção às necessidades das pessoas e países em situação de maior vulnerabilidade.'
            },
            ods8: {
                name: 'Trabalho Decente e Crescimento Econômico',
                desc:
                    'Promover o crescimento econômico sustentado, inclusivo e sustentável, o emprego pleno e produtivo e o trabalho decente para todos',
                text:
                    'No longo prazo, a desigualdade de renda e de oportunidades prejudica o crescimento econômico e o alcance do desenvolvimento sustentável. Os mais vulneráveis, muitas vezes, têm menores expectativas de vida e apresentam dificuldades de se libertarem de um círculo vicioso de insucesso escolar, baixas qualificações e poucas perspectivas de empregos de qualidade.\n\nA revitalização econômica contribui para criar melhores condições para a estabilidade e a sustentabilidade do país. É possível promover políticas que incentivem o empreendedorismo e a criação de empregos de forma sustentável e inclusiva. O ODS 8 reconhece a urgência de erradicar o trabalho forçado e formas análogas ao do trabalho escravo, bem como o tráfico de seres humanos, de modo a garantir a todos e todas o alcance pleno de seu potencial e capacidades.'
            },
            ods9: {
                name: 'Indústria, Inovação e Infraestrutura',
                desc:
                    'Construir infraestruturas resilientes, promover a industrialização inclusiva e sustentável e fomentar a inovação',
                text:
                    'Investimentos em infraestrutura e em inovação são condições básicas para o crescimento econômico e para o desenvolvimento das nações. Garantir uma rede de transporte público e infraestrutura urbana de qualidade são condições necessárias para o desenvolvimento sustentável. Por meio da promoção de eficiência energética e inclusão social, o progresso tecnológico é também uma das chaves para as soluções dos desafios econômicos e ambientais. Garantir a igualdade de acesso à tecnologias é crucial para promover a informação e conhecimento para todos. O ODS 9 lista metas que visam à construção de estruturas resilientes e modernas, ao fortalecimento industrial de forma eficiente, ao fomento da inovação, com valorização da micro e pequena empresa e inclusão dos mais vulneráveis aos sistemas financeiros e produtivos.'
            },
            ods10: {
                name: 'Redução da Desigualdades',
                desc: 'Reduzir a desigualdade dentro dos países e entre eles',
                text:
                    'O mundo é mais desigual hoje do que em qualquer momento da história desde 1940 Adesigualdade de renda e na distribuição da riqueza dentro dos países têm disparado, incapacitando os esforços de alcance dos resultados do desenvolvimento e de expansão das oportunidades e habilidades das pessoas, especialmente dos mais vulneráveis.\n\nA desigualdade é um problema global que requer soluções integradas. A visão estratégica deste objetivo se constrói sob o objetivo da erradicação da pobreza em todas suas dimensões, na redução das desigualdades socioeconômicas e no combate às discriminações de todos os tipos.\n\nSeu alcance depende de todos os setores na busca pela promoção de oportunidades para as pessoas mais excluídas no caminho do desenvolvimento. Foco importante do ODS 10 é o desafio contemporâneo das migrações e fluxos de pessoas deslocadas entre países e regiões devido a conflitos, eventos climáticos extremos ou perseguições de quaisquer tipo. O alcance de suas metas é estruturante para a realização de todos os outros 16 Objetivos de Desenvolvimento Sustentável.'
            },
            ods11: {
                name: 'Cidades e Comunidades Sustentáveis',
                desc:
                    'Tornar as cidades e os assentamentos humanos inclusivos, seguros, resilientes e sustentáveis',
                text:
                    'Em 2014, 54% da população mundial vivia em áreas urbanas, com projeção de crescimento para 66% em 2050. Em 2030, são estimadas 41 megalópoles com mais de 10 milhões de habitantes. Considerando que a pobreza extrema muitas vezes se concentra nestes espaços urbanos, as desigualdades sociais acabam sendo mais acentuadas e a violência se torna uma consequência das discrepâncias no acesso pleno à cidade. Transformar significativamente a construção e a gestão dos espaços urbanos é essencial para que o desenvolvimento sustentável seja alcançado. Temas intrinsecamente relacionados à urbanização, como mobilidade, gestão de resíduos sólidos e saneamento, estão incluídos nas metas do ODS 11, bem como o planejamento e aumento de resiliência dos assentamentos humanos, levando em conta as necessidades diferenciadas das áreas rurais, periurbanas e urbanas. O objetivo 11 está alinhado à Nova Agenda Urbana, acordada em outubro de 2016, durante a III Conferência das Nações Unidas sobre Moradia e Desenvolvimento Urbano Sustentável.'
            },
            ods12: {
                name: 'Consumo e Produção Responsáveis',
                desc: 'Assegurar padrões de produção e de consumo sustentáveis',
                text:
                    'Para alcançar as metas deste ODS, a mudança nos padrões de consumo e produção se configuram como medidas indispensáveis na redução da pegada ecológica sobre o meio ambiente. Essas medidas são a base do desenvolvimento econômico e social sustentável. As metas do ODS 12 visam a promoção da eficiência do uso de recursos energéticos e naturais, da infraestrutura sustentável, do acesso a serviços básicos. Além disso, o objetivo prioriza a informação, a gestão coordenada, a transparência e a responsabilização dos atores consumidores de recursos naturais como ferramentas chave para o alcance de padrões mais sustentáveis de produção e consumo.'
            },
            ods13: {
                name: 'Ação Contra a Mudança Global do Clima',
                desc: 'Tomar medidas urgentes para combater a mudança do clima e seus impactos',
                text:
                    'A mudança do clima é um evento transnacional, cujos impactos estão desregulando economias nacionais e afetando pessoas em todos os lugares, principalmente aquelas em situação de maior vulnerabilidade nos países em desenvolvimento. Sem a ação imediata frente à mudança do clima, a temperatura terrestre está projetada para aumentar mais de 3 ºC até o final do século XXI. Uma das metas para esse objetivo é mobilizar 100 milhões de dólares por ano até 2020 para ajudar os países em desenvolvimento no plano de mitigação de desastres relacionados ao clima. O estabelecimento do ODS 13 apenas para lidar com a questão do clima é encarado como estratégico para a mobilização dos atores capazes de promover as mudanças necessárias para impedir estas projeções de se tornarem realidade.'
            },
            ods14: {
                name: 'Vida na Água',
                desc:
                    'Conservar e promover o uso sustentável dos oceanos, dos mares e dos recursos marinhos para o desenvolvimento sustentável',
                text:
                    'Os oceanos tornam a vida humana possível por meio da provisão de segurança alimentar, transporte, fornecimento de energia, turismo, dentre outros. Além, por meio da regulação da sua temperatura, química, correntes e formas de vida, os oceanos regulam muitos dos dos serviços ecossistêmicos mais críticos do planeta, como ciclo do carbono e nitrogênio, regulação do clima, e produção de oxigênio. Além, os oceanos representam aproximadamente US$ 3 trilhões da economia global por ano, ou 5% do PIB global.\n\n40% dos oceanos estão sendo afetados incisiva e diretamente por atividades humanas, como poluição e pesca predatória, o que resulta, principalmente, em perda de habitat, introdução de espécies invasoras e acidificação. Nosso lixo também ajuda na degradação dos oceanos – há 13.000 pedaços de lixo plástico em cada quilômetro quadrado. É frente a esses desafios que os Objetivos de Desenvolvimento Sustentável indicam metas para gerenciar e proteger avida na água.'
            },
            ods15: {
                name: 'Vida Terrestre',
                desc:
                    'Proteger, recuperar e promover o uso sustentável dos ecossistemas terrestres, gerir de forma sustentável as florestas, combater a desertificação, deter e reverter a degradação da terra e deter a perda',
                text:
                    'Os seres humanos e outros animais dependem da natureza para terem alimento, ar puro, água limpa e também como um meio de combate à mudança do clima. As florestas, que cobrem 30% da superfície da Terra, ajudam a manter o ar e a água limpa e o clima da Terra em equilíbrio – sem mencionar que são o lar de milhões de espécies. Promover o manejo sustentável das florestas, o combate à desertificação, parar e reverter a degradação da terra, interromper o processo de perda de biodiversidade são algumas das metas que o ODS 15 promove. Usar sustentavelmente os recursos naturais em cadeias produtivas e em atividades de subsistência de comunidades, e integrá-los em políticas públicas é tarefa central para o atingimento destas metas e a promoção de todos os outros ODS.'
            },
            ods16: {
                name: 'Paz, Justiça e Instituições Eficazes',
                desc:
                    'Promover sociedades pacíficas e inclusivas para o desenvolvimento sustentável, proporcionar o acesso à justiça para todos e construir instituições eficazes, responsáveis e inclusivas em todos os níveis',
                text:
                    'Promover instituições fortes, inclusivas e transparentes, a manutenção da paz e o respeito aos direitos humanos baseados no Estado de direito são a base para o desenvolvimento humano sustentável. Estes são alguns dos princípios que sustentam as metas do ODS 16, que também inclui temas sensíveis, como o combate à exploração sexual, ao tráfico de pessoas e à tortura. Outros temas incluídos nas metas do ODS 16 são o enfrentamento à corrupção, ao terrorismo, a práticas criminosas, especialmente aquelas que ferem os direitos humanos.'
            },
            ods17: {
                name: 'Parcerias e Meios de Implementação',
                desc:
                    'Fortalecer os meios de implementação e revitalizar a parceria global para o desenvolvimento sustentável',
                text:
                    'Os ODS só serão realizados mediante um compromisso renovado de cooperação entre a comunidade internacional e uma parceria global ampla que inclua todos os setores interessados e as pessoas afetadas pelos processos de desenvolvimento. Os meios de implementação e as parcerias para o desenvolvimento sustentável são vitais para o crescimento sustentado e para o desenvolvimento sustentável das nações. O ODS 17 propõe o caminho para a realização efetiva da Agenda 2030 por todos os países, e a coordenação de esforços na arena internacional é essencial para isso. A Cooperação Sul-Sul e triangular, a transferência de tecnologia, o intercâmbio de dados e capital humano, bem como a assistência oficial ao desenvolvimento são alguns dos principais meios para o alcance dos ODS.'
            },
            sourcing: 'Fonte: ONU'
        },
        share: {
            title: 'Compartilhar meu mapa da transformação',
            subtitle: 'Quem são as pessoas que mais poderiam se interessar por isso?',
            fields: {
                name: 'Nome',
                email: 'E-mail'
            },
            invalid_field: {
                name: 'Nome Inválido',
                email: 'Email Inválido'
            },
            buttons: {
                cancel: 'Cancelar',
                share: 'Compartilhar Mapa da Transformação'
            },
            email_send_msg: 'Mapa compartilhado com sucesso!',
            bodyEmail: 'está compartilhando o mapa da transformação com você!',
            the: 'A',
            hello: 'Olá'
        },
        demonstration: {
            title: 'Solicitar uma demonstração',
            subtitle: 'Solicite uma demonstração da nossa plataforma e conheça todas as vantagens de como a seallintelligence pode ajudar você e sua empresa.',
            fields: {
                name: 'Nome',
                email: 'E-mail',
                telephone: 'Telefone'
            },
            invalid_field: {
                name: 'Nome Inválido',
                email: 'Email Inválido',
                telephone: 'Telefone inválido'
            },
            buttons: {
                cancel: 'Cancelar',
                request: 'Solicitar Demonstração'
            },
            email_send_msg: 'Solicitação realizada com sucesso!',
            bodyEmail: 'está solicitando uma demonstração para os seguintes contatos',
            bodyCurrentUserEmail: 'Sua solicitação foi realizada com sucesso para os seguintes contatos.',
            the: 'A',
            hello: 'Olá'
        }
    },
    header: {
        perfil: 'Meu perfil',
        impacto: 'Mapa da Transformação',
        jornada: 'A Jornada',
        ods: 'Alinhamento ODS',
        esg: 'Diagnóstico ESG'
    },
    mais: {
        header: {
            title: 'Cadastrar OSC'
        },
        tab: {
            title_1: 'Informações sobre o solicitante',
            title_2: 'Informações da OSC'
        },
        form: {
            createdByEmail: {
                field: 'E-mail',
                field_error_msg: 'E-mail inválido'
            },
            createdByCPF: {
                field: 'CPF',
                field_error_msg: 'CPF inválido'
            },
            createdByName: {
                field: 'Nome',
                field_error_msg: 'Nome inválido'
            },
            createdByRelations: {
                field: 'Sua Relação com a OSC'
            },
            cnpj: {
                field: 'CNPJ',
                field_error_msg: 'CNPJ inválido'
            },
            name: {
                field: 'Nome Fantasia',
                field_error_msg: 'Nome Fantasia inválido'
            },
            country: {
                field: 'País',
                selected: 'Selecione uma país'
            },
            city: {
                field: 'Cidade',
                selected: 'Selecione uma cidade'
            },
            state: {
                field: 'UF',
                selected: 'Selecione um UF'
            },
            telephone: {
                field: 'Telefone'
            },
            sector: {
                field: 'Área de Atuação'
            },
            site: {
                field: 'Site'
            },
            quantityEmployee: {
                field: 'Número de Beneficiários'
            },
            whoBeneficiaries: {
                field: 'Quem são os beneficiários da sua organização social?'
            },
            email: {
                field: 'E-mail da OSC'
            },
            password: {
                field: 'Senha'
            },
            address: {
                field: 'Endereço'
            },
            breed: {
                field: 'Raça/Cor do responsável pela organização'
            },
            ageGroup: {
                field: 'Faixa etária do responsável pela organização'
            },
            genre: {
                field: 'Gênero do responsável pela organização'
            },
            seals: {
                field: 'A Organização possui algum selo?'
            },
            ceo: {
                field: 'Nome do Responsável',
                field_error_msg: 'Nome do Responsável inválido'
            },
            typeCert: {
                field: 'Certificado ou Título',
                field_error_msg: 'Selecione Certificado ou Título'
            },
            nameCert: { field: 'Nome do Certificado ou Título', field_error_msg: 'Nome Inválido' },
            initialDateCert: {
                field: 'Início da Validade',
                field_error_msg: 'Início da Validade inválido'
            },
            endDateCert: { field: 'Fim da Validade', field_error_msg: 'Fim da Validade inválido' },
            boardDirectors: {
                field: 'Quadro de dirigentes',
                field_error_msg: 'Quadro de dirigentes inválido'
            },
            resourceFronts: {
                field: 'Fontes de Recursos Anual',
                field_error_msg: 'Fontes de Recursos Anual inválido'
            },
            resourceTotals: {
                field: 'Total de Recursos Anual',
                field_error_msg: 'Total de Recursos Anual inválido'
            },
            legalNature: {
                field: 'Natureza Jurídica',
                field_error_msg: 'Natureza Jurídica inválido'
            },
            common: {
                required_field: 'Campo Obrigatório'
            },
            btn: {
                save: 'Acessar Seall Intelligence'
            }
        },
        error_backend: {
            already_exists: 'OSC Já Cadastrada!'
        }
    },
    common: {
        error_http: 'Alguma coisa aconteceu! Tente Novamente'
    },
    esg: {
        Ambiental: {
            title: 'Ambiental',
            description: `<p>Os critérios e parâmetros utilizados refletem o desenvolvimento
                        sustentável ambientalmente correto, que dissocia crescimento socioeconômico da não
                        preservação e respeito ao meio ambiente. Expressa a verificação do equilíbrio das ações
                        humanas e financeiras às realidades, necessidades e mudanças ambientais, contemplando
                        a adoção de padrões de consumo e produção mais responsáveis.</p>
                        <p>Temáticas principais abordadas:</p>
                        <p>- Energia</p>
                        <p>- Água</p>
                        <p>- Resíduos</p>
                        <p>- Gestão das emissões de gases do efeito estufa</p>
                        <p>- Biodiversidade</p>
                        <p>As temáticas abordadas no Diagnóstico ESG estão alinhadas aos principais protocolos
                        nacionais e internacionais para sustentabilidade, impacto e investimentos responsáveis.</p>`
        },
        Social: {
            title: 'Social',
            description: `
                    <p>Os critérios e parâmetros utilizados refletem o desenvolvimento por ações
                    socialmente sustentáveis, que valorizem e respeitem o capital humano envolvido e
                    beneficiados. Expressa a verificação do alcance de padrões mais inclusivos, justos e
                    transparentes de responsabilidade social.
                    </p>
                    <p>Temáticas principais abordadas:</p>
                    <p>- Diversidade (política de recursos humanos)</p>
                    <p>- Saúde, segurança e bem-estar dos colaboradores</p>
                    <p>- Segurança do cliente</p>
                    <p>As temáticas abordadas no Diagnóstico ESG estão alinhadas aos principais protocolos
                    nacionais e internacionais para sustentabilidade, impacto e investimentos responsáveis.</p>`
        },
        Governança: {
            title: 'Governança',
            description: `
<p>Os critérios e parâmetros utilizados refletem o desenvolvimento dos
processos, regulamentos, e desempenho econômico sustentável, da operação e das
relações em toda a cadeia, contemplando o alcance de padrões mais transparentes e
efetivos de gestão e governança corporativa. Expressa a verificação do equilíbrio entre
equidade, eficiência, resultado e harmonia nas relações operacionais e econômicas
estabelecidas.</p>
<p>Temáticas principais abordadas:</p>
<p>- Diversidade em órgãos de governança.</p>
<p>- Governança corporativa e compliance (anticorrupção)</p>
<p>- Governança corporativa e compliance (estratégica e reporte ESG)</p>
<p>- Governança corporativa e compliance (qualificação e verificação)</p>

<p>As temáticas abordadas no Diagnóstico ESG estão alinhadas aos principais protocolos
nacionais e internacionais para sustentabilidade, impacto e investimentos responsáveis.</p>
            `
        }
    }
};
