export default {
    main: {
        message: '',
        required_field: 'Required Field',
        form_invalid: 'Please, fill all required fields',
        logout: 'Sign out',
        error_main: 'An error occurred',
        fill_organization: 'Please, create your organization'
    },
    landing: {
        header: {
            home: 'HOME',
            who: 'ABOUT US',
            we_do: 'WHAT WE DO',
            for: 'FOR WHOM',
            solucion: 'OUR SOLUTION',
            contact_us: 'CONTACT US'
        },
        texts_background: {
            title: 'a platform for intelligent management of your impact journey.',
            subtitle:
                'Learn about SEALL’s new solution which supports organizations, social entrepreneurs, and impact businesses to make decisions based on data that matters.',
            button: 'I want to try!'
        },
        texts_aside: {
            title:
                'Nowadays, those who measure the impact produced by their organization, are one step ahead.',
            text:
                'SEALL Intelligence was born to meet the need of organizations to execute a responsible and transparent impact management. Through reliable data, conscious leaders can make more and more assertive decisions which generate greater value for their strategic audiences.',
            subtile:
                'The goal is for leaders to manage data about their organization in a transparent and straightforward manner.',
            button: 'Do you want to try?'
        },
        texts_cards: {
            title:
                'This free platform is for those who wish to have clear information at hand in order to:',
            card1: 'Generate data from a methodology based on acknowledged international standards',
            card2:
                'Have clarity and reliable data to make more effective decisions and support their strategy',
            card3: 'Present relevant data to partners and potential investors and/or customers',
            card4: 'Monitor and improve their processes on a continuing basis',
            card5: 'Keep their data updated',
            card6: 'Check the compliance with targets',
            card7: 'Unify the impact vision',
            card8: 'Scale their organization’s impact',
            card9: 'Measure the impact in a transparent manner'
        },
        texts_box: {
            title:
                'In order for your organization to become stronger and have full view of the impact it generates in the world we facilitate your measurement process. We make our methodology available to you for effective and innovative management of your organization.',
            button: 'I want to try!'
        },
        texts_who: {
            title: 'Who is SEALL Intelligence?',
            subtitle:
                'SEALL is a company of the Houer Group. Together we reinforce our desire to guide organizations in the creation of a better world.',
            text: 'Our mission is to help you strengthen your organization’s legacy.'
        },
        texts_wedo: {
            title: 'What do we do?',
            subtitle:
                'Among our services, we make SEALL Intelligence available, an exclusive platform of impact management.',
            button: 'I want to try!'
        },
        texts_people: {
            title:
                'We contribute to leaders and organizations that deal with the complexity to prove the impact of their activities.'
        },
        texts_solucion: {
            title:
                'We facilitate the understanding of global matrixes to democratize and optimize socioenvironmental and economic impact management of projects, initiatives, and organizations.'
        }
    },
    login: {
        main: {
            create: 'Create your account',
            login: 'Access your account',
            email: 'E-mail',
            pass: 'Password',
            placeh_email: '',
            placeh_pass: '',
            text: 'Based on the Theory of Change',
            email_invalid: 'Invalid E-mail',
            password_invalid:
                'The password must be have a upper letter, a lower letter, a number, a special characther and minimium 8 characters. For example, Aa12345*',
            error_login: 'Invalid e-mail or password'
        },
        create: {
            term: 'Create an account means you agree with our Terms of Service and Privacy Policy',
            button: 'Create my transformation map',
            message: 'For security purpose, insert below the code sent to your e-mail',
            placeh_code: 'Activation code',
            resend: 'Resend code',
            confirm: 'Confirm code',
            back: 'Back',
            use_term: 'Terms of Use',
            button_close: 'Close',
            terms:
                'I am aware that under the terms of the General Personal Data Protection Law [Brazil] no. 13709, August 14, 2018 (Portuguese: Lei Geral de Proteção de Dados Pessoais, LGPD), my data will be treated and kept in this database for the purpose of registering the individual as an external user in the computerized system of this platform. I am aware that I have the right to obtain from the company SEALL, at any time and upon request, all the information concerning my data and its respective treatment. I am aware and I agree that my data may be given to partners for the purposes of personalized advertisements and contents, measurement of advertisements and contents, audience perspectives and development of products and services.'
        },
        login: {
            link: 'Forgot my password',
            button: 'Acess my account'
        },
        forgot: {
            title: 'Password Recovery',
            subtitle:
                'When requesting password recovery, an email with a verification code will be sent to the email provided',
            button_next: 'Continue',
            verification_code: 'Verification code',
            email: 'E-mail',
            new_password: 'New Password',
            recover_password: 'Recovery',
            message_success: 'Updated Password Successfully',
            message_warn_two: 'Some error happened, check if the verification code is correct',
            message_warn: 'Some error happened, try again later'
        }
    },
    presentation: {
        title: 'Begin your impact journey',
        subtile:
            'In some stages you will manage to understand, measure, and manage your company’s impact in the world, understand how',
        button_top: 'I want to be an impact leader ',
        button_bottom: 'I want to begin my journey now',
        cards: {
            card1: {
                title: 'Transformation Map',
                description:
                    'We use the Theory of Change approach to help you reflect and communicate the first visions of your impact, including the first alignment with the 2030 Agenda with our Radar of Strategic Alignment with the SDGs.',
                type: 'FREE'
            },
            card2: {
                title: 'Perception of the Audiences',
                description:
                    'You can collect impact perceptions from your internal and external stakeholders and select the most relevant protocols for your organization.',
                type: 'BASIC'
            },
            card3: {
                title: 'Materiality Matrix',
                description:
                    'Transform what is intangible into material. With the materiality at hand the main impact themes that you need to work on in your organization will be clearer.',
                type: 'BASIC'
            },
            card4: {
                title: 'Indicator Matrix',
                description:
                    'Select the indicators that your organization already monitors and be clear about the ones that are still missing or that should be included for capturing your impact.',
                type: 'INTERMEDIATE'
            },
            card5: {
                title: 'Impact Management',
                description:
                    'Here you can monitor the day-to-day of the change that you make in the world and become an impact leader! Our system permits an effective management of each indicator and related actions.',
                type: 'INTERMEDIATE'
            },
            card6: {
                title: 'Impact Outcome',
                description:
                    'Have access to a shareable report with the main data and outcomes of your impact for a more effective communication with your stakeholders, including investors and financial institutions.',
                type: 'INTERMEDIATE'
            }
        }
    },
    questions: {
        title: 'Begin your transformation map',
        subtitle:
            'In order to begin, we need some information that will guide our journey. Let’s begin?',
        info:
            'Based on your organization or initiative, it is fundamental that you fill in all the blanks carefully and attentively. It is this information that will guide us during all the journey and that will base your transformation map.',
        button_top: 'I want to be an impact leader',
        button_bottom: 'Save and continue',
        how_to_use: 'Press enter to add an item and Press backspace to remove the last item',
        save: 'Save',
        cards: {
            card1: 'Transformation map',
            card2: 'Perception of the Audiences',
            card3: 'Materiality Matrix',
            card4: 'Indicator Matrix',
            card5: 'Impact Management',
            card6: 'Impact Outcome'
        },
        index: {
            tipo: 'Type',
            nome: 'Name',
            missao: 'Mission',
            paraquem: 'For whom',
            problema: 'Problem',
            solucao: 'Solution',
            atividades: 'Activities',
            efeitos: 'Measurable effects',
            resultados: 'Outcomes',
            impacto: 'Impact'
        },
        questions: {
            question: {
                question: 'At this stage we will be dealing with:',
                option1: 'Your organization',
                option2: 'An initiative of the organization'
            },
            question0: {
                question: 'Name of the initiative',
                placeholder: 'What is your initiative’s mission?'
            },
            question1: {
                question: 'Mission',
                placeholder: 'What is your initiative’s mission? '
            },
            question2: {
                question: 'For whom?',
                placeholder: 'Who are the people impacted by and/or benefitted from it?',
                items: 'Use as example: Teachers, Students, Schools in the city of Campinas, SP'
            },
            question3: {
                question: 'Understanding the problem',
                placeholder: 'What problem does it solve? If your initiative was not in the world',
                items: 'Use as example: Lack of infrastructure, Lack of time'
            },
            question4: {
                question: 'Solutions',
                placeholder: 'What solutions does your initiative deliver?',
                items: 'Use as example: DL Lessons, Onsite events, Conferences, Products'
            },
            question5: {
                question: 'Activities',
                placeholder:
                    'What are the most important key activities for the solution(s) to be delivered?',
                items: 'Use as example: Teachers’ qualification, Students’ education'
            },
            question6: {
                question: 'Measurable effects',
                placeholder: 'What measurable e quantifiable effects do you see?',
                items: 'Use as example: Number of qualified teachers, Attendance rate, NPS'
            },
            question7: {
                question: 'Outcomes',
                placeholder: 'What result(s) can be seen, after trying out your solution(s)?',
                items: 'Use as example: Number of qualified teachers'
            },
            question8: {
                question: 'Impact',
                placeholder: 'What is the long-term impact of your initiative?',
                items:
                    'Use as example: Mapping of new technologies, Local development, Strengthening trade, Reducing'
            }
        },
        modal: {
            title: 'Congratulations!',
            subtitle: 'You have begun your transformation journey.',
            info:
                'We need to learn a little more about you so we can help you on this journey. Please, now fill in some data about your organization.',
            button_go: 'Continue',
            button_back: 'Return'
        }
    },
    organization: {
        title: 'Organizational Profile',
        subtitle:
            'Fill in the blanks using your organization data. Don’t worry, they will be used only to guarantee that your map is exclusive and custom-tailored.',
        button_top: 'I want to be an impact leader',
        button_bottom: 'Save and continue',
        cards: {
            card1: 'Transformation map',
            card2: 'Perception of the Audiences',
            card3: 'Materiality Matrix',
            card4: 'Indicator Matrix',
            card5: 'Impact Management',
            card6: 'Impact Outcome'
        },
        itens: {
            avatar: 'Add avatar',
            name: 'Organization’s name',
            name_placeholder: 'Write it here',
            cnpj: 'EIN (Employer Identification Number or similar in your country)',
            cnpj_placeholder: 'Write it here',
            segment: 'Area/Segment',
            segment_placeholder: 'Select',
            type: 'Organization Type',
            type_placeholder: 'Select an option',
            number: 'Number of employees',
            number_placeholder: 'Select',
            city: 'City',
            city_placeholder: 'Select',
            state: 'State',
            state_placeholder: 'Select',
            country: 'Country',
            country_placeholder: 'Select'
        },
        types: {
            public: 'Public',
            private: 'Private',
            third_sector: 'Third Sector',
            impact_business: 'Impact Business'
        },
        areas: {
            A: {
                value: 'A',
                text: 'AGRICULTURE, LIVESTOCK, FORESTRY PRODUCTION, FISHING AND AQUACULTURE'
            },
            B: { value: 'B', text: 'EXTRACTIVE INDUSTRIES' },
            C: { value: 'C', text: 'PROCESSING INDUSTRIES' },
            D: { value: 'D', text: 'ELECTRICITY AND GAS' },
            E: {
                value: 'E',
                text: 'WATER, SEWAGE, WASTE MANAGEMENT ACTIVITIES AND DECONTAMINATION '
            },
            F: { value: 'F', text: 'CONSTRUCTION' },
            G: { value: 'G', text: 'TRADE; REPAIR OF MOTOR VEHICLES AND MOTORCYCLES' },
            H: { value: 'H', text: 'TRANSPORT, STORAGE AND MAIL' },
            I: { value: 'I', text: 'BOARD AND LODGING ' },
            J: { value: 'J', text: 'INFORMATION AND COMMUNICATION' },
            K: { value: 'K', text: 'FINANCIAL, INSURANCE AND RELATED SERVICES ACTIVITIES' },
            L: { value: 'L', text: 'REAL ESTATE ACTIVITIES' },
            M: { value: 'M', text: 'PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES' },
            N: { value: 'N', text: 'ADMINISTRATIVE ACTIVITIES AND COMPLEMENTARY SERVICES' },
            O: { value: 'O', text: 'PUBLIC ADMINISTRATION, DEFENSE AND SOCIAL SECURITY' },
            P: { value: 'P', text: 'EDUCATION' },
            Q: { value: 'Q', text: 'HUMAN HEALTH AND SOCIAL SERVICES' },
            R: { value: 'R', text: 'ARTS, CULTURE, SPORTS AND RECREATION' },
            S: { value: 'S', text: 'OTHER SERVICE ACTIVITIES' },
            T: { value: 'T', text: 'HOUSEHOLD CHORES' },
            U: {
                value: 'U',
                text: 'INTERNATIONAL ORGANIZATIONS AND OTHER EXTRATERRITORIAL INSTITUTIONS'
            }
        }
    },
    legacy: {
        title: 'You are just a few questions from your map',
        subtitle:
            'Answer the following questions. They are the ones that, totally free of charge, will help us understand the relation of your initiatives with the 2030 Agenda and the Sustainable Development Goals (SDGs). This process will take, at the most, 10 minutes. And it is just the beginning of your journey towards an intelligent impact management of your organization.',
        info: 'In your perception, what is the contribution of your initiative to:',
        scale: {
            bad: 'No contribution',
            good: 'Very significant contribution'
        },
        button_top: 'I want to be an impact leader',
        button_bottom: 'Save and continue',
        tooltip: 'Click on the question numbers to navigate between them.',
        cards: {
            card1: 'Transformation map',
            card2: 'Perception of the Audiences',
            card3: 'Materiality Matrix',
            card4: 'Indicator Matrix',
            card5: 'Impact Management',
            card6: 'Impact Outcome'
        },
        questions: {
            question0:
                'Eradicate hunger, create access to safe and high-quality food, promote and support adequate nutrition, and reduce food losses and food waste.',
            question1:
                'Promote wellness and health, generate access to treatment and medicines, raise awareness and reduce incidences and fatalities from diseases.',
            question2:
                'Promote and generate access to basic and essential services, such as housing, infrastructure, access to energy, technology, or financial services, among others.',
            question3:
                'Enable access to education and qualification, formal or non-formal, in basic, technical, or high levels, in an inclusive way and capable of promoting learning and professionalization, including education for sustainable development.',
            question4:
                'Support and develop agriculture in a sustainable, resilient, and innovative manner that supports development and promotes employment and income generation.',
            question5:
                'Strengthen responsive decision-making, transparency and anti-corruption practices, and efficient management of public and private organizations.',
            question6:
                'Promote and support the development and generation of clean energy, from renewable and safe sources, that can contribute to the improvement of energy efficiency and climate change mitigation.',
            question7:
                'Promote and encourage economic development, the generation of work posts and income, the diversification and modernization of the economy, in addition to strengthening the industry, local companies, micro and small business owners, and producers.',
            question8:
                'Guarantee and promote fundamental rights, in respect and concordance with legislation and sustainable development.',
            question9:
                'Ensure and promote access to safe and quality sanitation and responsible water and sewage management, contributing to universal access to safe drinking water in places of vulnerability and in desert climatic conditions, and reducing incidents and diseases due to lack of sanitation.',
            question10:
                'Guarantee and promote equal opportunities for all people, gender equality, respect for the disabled, respect for all differences, non-discrimination, and collective participation.',
            question11:
                'Promote or encourage financial investments for the development of infrastructure, science and technology, medicine, and markets in favor of the sustainable development of society and institutions.',
            question12:
                'Promote and ensure security for all and access to justice, combat all forms of violence, address the occurrence of crime and criminality at all levels.',
            question13:
                'Develop and support actions in favor of environmental quality, encouraging the reduction and mitigation of water, air and soil pollution, as well as the preservation and maintenance of biodiversity (fauna and flora).',
            question14:
                'Eradicate poverty and vulnerability, in all their dimensions, for all people everywhere.',
            question15:
                'Promote the responsible and sustainable use of natural resources, including the reduction of waste generated through recycling and reuse practices.',
            question16:
                'Reduce and control social and environmental risks, such as disasters, accidents, epidemics, contamination, and others.'
        },
        modal: {
            title: 'Congratulations!',
            subtitle: 'You are closer and closer to becoming an impact leader',
            maissubtitle:
                'You complete your register, click on continue to you see Impact Social Map',
            info:
                'Rate 1 to 5 on the difficulty of the questionnaire, “1” very easy and “5” very difficult.',
            button_go_mais: 'Continue to MAIS Platform',
            button_go_radar: 'Generate Radar of Strategic Alignment of OSC',
            button_go: 'Continue',
            button_back: 'Return',
            scale: {
                bad: 'Very easy',
                good: 'Very difficult'
            }
        }
    },
    map: {
        title: 'INICIATIVE',
        mission: 'Mission',
        map: 'Transformation map',
        map_info: 'Connect the dots! Link the facts that have a direct relation.',
        radar: 'Radar of Strategic Alignment with the SDGs',
        radar_info:
            'See how much easier it became to perceive how your actions are linked to the SDGs.',
        button_top: 'I want to be an impact leader',
        button_bottom: 'I want to be an impact leader',
        button_share: 'Share my map',
        button_download: 'Download my map',
        button_edit: 'Change information',
        button_pdf: 'Download PDF map',
        mapwords: {
            para: {
                nome: 'For:',
                itens: ['Teachers', 'Students', 'Schools in the city of Campinas, SP']
            },
            problemas: {
                nome: 'Having problems regarding:',
                itens: ['Lack of infrastructure', 'Lack of time']
            },
            propomos: {
                nome: 'We propose solutions:',
                itens: ['DL Lessons', 'Onsite events', 'Conferences', 'Products']
            },
            atividades: {
                nome: 'The key activities are:',
                itens: ['Teachers’ qualification', 'Students’ education']
            },
            medimos: {
                nome: 'Where we measured',
                itens: ['Number of qualified teachers', 'Attendance rate', 'NPS']
            },
            resultados: {
                nome: 'Outcome',
                itens: ['Number of qualified teachers']
            },
            solucoes: {
                nome: 'Solutions',
                itens: [
                    'Mapping of new technologies',
                    'Local development',
                    'Strengthening trade',
                    'Reducing child mortality'
                ]
            }
        },
        texts: {
            click_max: 'Learn more',
            click_min: 'Learn less',
            text_card: 'of Alignment with the SDGs'
        },
        cards: {
            card1: 'Transformation map',
            card2: 'Perception of the Audiences',
            card3: 'Materiality Matrix',
            card4: 'Indicator Matrix',
            card5: 'Impact Management',
            card6: 'Impact Outcome'
        },
        ods: {
            ods1: {
                name: 'No Poverty',
                desc: 'End poverty in all its forms everywhere',
                text: ''
            },
            ods2: {
                name: 'Zero Hunger',
                desc:
                    'End hunger, achieve food security and improved nutrition and promote sustainable agriculture',
                text: ''
            },
            ods3: {
                name: 'Good Health and Well-Being',
                desc: 'Ensure healthy lives and promote well-being for all at all ages',
                text: ''
            },
            ods4: {
                name: 'Quality Education',
                desc:
                    'Ensure Inclusive and equitable quality education and promote lifelong learning opportunities for all',
                text: ''
            },
            ods5: {
                name: 'Gender Equality',
                desc: 'Achieve gender equality and empower all women and girls ',
                text: ''
            },
            ods6: {
                name: 'Clean water and sanitation',
                desc:
                    'Ensure availability and sustainable management of water and sanitation for all',
                text: ''
            },
            ods7: {
                name: 'Affordable and clean energy',
                desc:
                    'Ensure access to affordable, reliable, sustainable and modern energy for all',
                text: ''
            },
            ods8: {
                name: 'Decent work and economic Growth',
                desc:
                    'Promote sustained,  inclusive and sustainable economic growth, full and productive employment and decent work for all',
                text: ''
            },
            ods9: {
                name: 'Industries, innovation and infrastructure',
                desc:
                    'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation ',
                text: ''
            },
            ods10: {
                name: 'Reduced Inequalities',
                desc: 'Reduce inequality within and among countries',
                text: ''
            },
            ods11: {
                name: 'Sustainable Cities and Communities',
                desc:
                    'Make cities and human settlements inclusive, safe, resilient and sustainable',
                text: ''
            },
            ods12: {
                name: 'Responsible Consumption and Production',
                desc: 'Ensure sustainable consumption and production patterns',
                text: ''
            },
            ods13: {
                name: 'Climate Action',
                desc: 'Take urgent action to combat climate change and its impacts',
                text: ''
            },
            ods14: {
                name: 'Life below water',
                desc:
                    'Conserve and sustainably use the oceans, seas and marine resources for sustainable development',
                text: ''
            },
            ods15: {
                name: 'Life on Land',
                desc:
                    'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss',
                text: ''
            },
            ods16: {
                name: 'Peace, justice and strong institutions ',
                desc:
                    'Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels',
                text: ''
            },
            ods17: {
                name: 'Partnerships for the goals ',
                desc:
                    'Strengthen the mans of implementations and revitalize the global partnership for sustainable development ',
                text: ''
            },
            sourcing: 'Source: UN'
        },
        share: {
            title: 'Share my ransformation map',
            subtitle: 'Who are the people that might be most interested in this?',
            fields: {
                name: 'Name',
                email: 'E-mail'
            },
            invalid_field: {
                name: 'Invalid Name',
                email: 'Invalid E-mail'
            },
            buttons: {
                cancel: 'Cancel',
                share: 'Share Transformation Map'
            },
            email_send_msg: 'Map shared sucessfuly!',
            bodyEmail: 'is sharing own transformation map with you!',
            the: 'The',
            hello: 'Hello'
        }
    },
    header: {
        perfil: 'My profile',
        impacto: 'Transformation Map',
        jornada: 'The Journey',
        ods: 'The SDGs',
        esg: 'ESG Radar'
    },
    mais: {
        header: {
            title: 'Register OSC'
        },
        form: {
            cnpj: {
                field: 'CNPJ',
                field_error_msg: 'CNPJ inválido'
            },
            name: {
                field: 'Name',
                field_error_msg: 'Invalid Name'
            },
            country: {
                field: 'Country',
                selected: 'Select a Country'
            },
            city: {
                field: 'City',
                selected: 'Select a City'
            },
            state: {
                field: 'State',
                selected: 'Select a State'
            },
            telephone: {
                field: 'Telephone'
            },
            sector: {
                field: 'Action Sector'
            },
            site: {
                field: 'Site'
            },
            employeeQuantity: {
                field: 'Number of Beneficiaries'
            },
            email: {
                field: 'E-mail'
            },
            password: {
                field: 'Password'
            },
            ceo: {
                field: 'Responsible Name',
                field_error_msg: 'Invalid Responsible Name'
            },
            common: {
                required_field: 'Required Field'
            },
            btn: {
                save: 'Sign in Seall Intelligence'
            }
        }
    },
    common: {
        error_http: 'Sorry an occured error! Try again'
    }
};
