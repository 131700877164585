<template>
  <div lang="pt-BR" link="#000080" vlink="#800000" dir="ltr">
    <div title="header">
      <p
        align="left"
        style="
          margin-top: 0cm;
          margin-bottom: 1.15cm;
          line-height: 100%;
          orphans: 2;
          widows: 2;
        "
      >
        <span
          class="sd-abs-pos"
          style="position: absolute; top: -0.83cm; left: 0cm; width: 90px"
        ></span
        ><br />
      </p>
    </div>
    <h2
      class="western"
      align="center"
      style="margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif"
        >TERMO DE CONSENTIMENTO DE ACORDO COM A LEI GERAL DE</font
      >
    </h2>
    <h2
      class="western"
      align="center"
      style="margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif">PROTEÇÃO DE DADOS – LGPD</font>
    </h2>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"><b>A SEALL</b></font
      ><font face="Calibri, serif"
        >, pessoa jurídica de direito privado, inscrita no CNPJ sob nº
        29.700.229/0001-10, com sede a Avenida João Pinheiro, nº 146, 10º Andar,
        bairro Lourdes, na cidade de Belo Horizonte/MG, neste ato tão somente
        denominada SEALL, está empenhada em salvaguardar a sua privacidade. Este
        termo tem a finalidade de deixar o mais claro possível a nossa política
        de coleta e compartilhamento de dados, informando sobre os dados
        coletados e como os utilizamos.
      </font>
    </p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >Ao utilizar os nossos serviços, você declara o seu </font
      ><font face="Calibri, serif"><b>EXPRESSO CONSENTIMENTO </b></font
      ><font face="Calibri, serif"
        >para coletarmos, tratarmos e armazenarmos dados sobre você quando
        julgarmos necessários à prestação de nossos serviços, tais como:</font
      >
    </p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <ul>
      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"
            ><b>Informações que você oferece. </b></font
          ><font face="Calibri, serif"
            >Coletamos os dados fornecidos por você no cadastro diante a
            plataforma SEALL Intelligence, tais como </font
          ><font face="Calibri, serif"><b>nome e</b></font
          ><font face="Calibri, serif"
            ><b>
              sobrenome, endereço para correspondência, endereço de e-mail,
              informações de pagamento, bem como outras informações de contato
              on-line ou número de telefone</b
            ></font
          ><font face="Calibri, serif"
            >, foto e demais informações requeridas no cadastro.</font
          >
        </p>
      </li>

      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"><b>Comunicação. </b></font
          ><font face="Calibri, serif"
            >Podemos registrar e gravar todos os dados fornecidos em toda
            comunicação realizada com nossa equipe, seja por correio eletrônico,
            mensagens, telefone ou qualquer outro meio.</font
          >
        </p>
      </li>

      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"
            ><b>Informações sobre pagamentos. </b></font
          ><font face="Calibri, serif"
            >Ao submeter seus dados para pagamento, podemos coletar informações
            sobre a compra ou transação. Isso abrange suas informações de
            pagamento, como os dados do seu cartão de crédito ou débito,
            informações de conta e autenticação, além dos dados de faturamento,
            tais como endereço completo, CPF e CNPJ.</font
          >
        </p>
      </li>

      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"><b>Endereço eletrônico</b></font
          ><font face="Calibri, serif"> </font
          ><font face="Calibri, serif"><b>(e-mail).</b></font
          ><font face="Calibri, serif">
            Ao fazer login na plataforma, coletaremos o seu e-mail para fins
            cadastrais, pelo qual ocorrerão as comunicações de atualizações da
            plataforma, promoções e gerenciamento de sua conta.</font
          >
        </p>
      </li>

      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"><b>Cookies.</b></font
          ><font face="Calibri, serif">
            Registramos dados de sua visita à plataforma através de cookies e
            outras tecnologias de rastreamento incluindo seu endereço IP e nome
            de domínio, a versão do seu navegador e do seu sistema operacional,
            dados de tráfego online, dados de localização, logs da web e outros
            dados de navegação.</font
          >
        </p>
      </li>
    </ul>

    <p
      class="western"
      style="margin-left: 1.27cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <br />
    </p>
    <ol type="I">
      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"
            ><b>FINALIDADES: UTILIZAÇÃO DESTAS INFORMAÇÕES</b></font
          >
        </p>
      </li>
    </ol>

    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >Ao utilizar a plataforma você AUTORIZA EXPRESSAMENTE o uso destas
        informações para as seguintes FINALIDADES:</font
      >
    </p>
    <ul>
      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"
            ><b
              >Fornecer, melhorar e desenvolver os Serviços disponíveis.</b
            ></font
          ><font face="Calibri, serif">
            Usamos cookies e tecnologias semelhantes para fornecer e apoiar
            nossos Serviços, através da personalização do conteúdo
            compartilhado, para garantir que o conteúdo da plataforma seja
            relevante.
          </font>
        </p>
      </li>

      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"><b>Construção de conteúdo.</b></font
          ><font face="Calibri, serif">
            Todo conteúdo compartilhado, ou divulgado, seja através de
            comentários, dicas ou sugestões de melhorias compõem o banco de
            dados da plataforma viabilizando a melhoria e conhecimento do
            produto pelos demais usuários.</font
          >
        </p>
      </li>

      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"
            ><b
              >Para reorganizar ou fazer mudanças operacionais e gerenciais da
              plataforma.</b
            ></font
          ><font face="Calibri, serif">
            Os dados coletados serão utilizados na avaliação de eventuais
            negociações para a venda da plataforma a terceiros; para uma
            eventual reorganização interna, com a a transferência de dados a
            terceiros (ou seus assessores) como parte de um processo de </font
          ><font face="Calibri, serif"><i>due diligence</i></font
          ><font face="Calibri, serif">
            com a finalidade de analisar uma proposta de venda ou
            reorganização.</font
          >
        </p>
      </li>

      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"><b>Monitoramento de uso</b></font
          ><font face="Calibri, serif"
            >. Utilizamos seus dados para monitorar atividades e transações com
            a finalidade de garantir a qualidade do serviço, o atendimento à
            leis aplicáveis, o cumprimento de procedimentos e para combater a
            fraude.</font
          >
        </p>
      </li>

      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"><b>Obrigações legais.</b></font
          ><font face="Calibri, serif">
            Compartilhamos seus dados com terceiros para cumprir exigências
            legais, regulatórias ou fiscais, envolvendo a divulgação de seus
            dados pessoais a terceiros, a um tribunal, reguladores ou agências
            governamentais.</font
          >
        </p>
      </li>
    </ul>

    <p
      class="western"
      style="margin-left: 1.27cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <br />
    </p>
    <ol type="I" start="2">
      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"><b>COMPARTILHAMENTO DE DADOS</b></font>
        </p>
      </li>
    </ol>

    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >Compartilhamos os seus dados com parceiros empresariais, para fins de
        viabilizar pagamentos e o processamento de dados, bem como para tornar o
        produto mais relevante e eficiente à sua finalidade.</font
      >
    </p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >Compartilhamos seus dados com terceiros para cumprir exigências legais,
        regulatórias ou fiscais, envolvendo a divulgação de seus dados pessoais
        a terceiros, a um tribunal, reguladores ou agências
        governamentais.</font
      >
    </p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <ol type="I" start="3">
      <li>
        <h2 class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"
            >ONDE ARMAZENAMOS E PROCESSAMOS SEUS DADOS</font
          >
        </h2>
      </li>
    </ol>

    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >Os dados coletados são armazenados e processados dentro da plataforma
        SEALL Intelligence, para fins de melhorar o desempenho e proteger os
        dados no caso de uma falha ou outro problema.
      </font>
    </p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <ol type="I" start="4">
      <li>
        <h2 class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"
            >RESPONSABILIDADES DO CONTROLADOR E MEDIDAS DE SEGURANÇA</font
          >
        </h2>
      </li>
    </ol>

    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >Todos os dados que você nos fornece são tratados unicamente para
        atingir as finalidades acima listadas. Estes dados são armazenados em
        servidores seguros nossos ou de fornecedores contratados, acessados e
        utilizados de acordo com nossas políticas e padrões de segurança. Todo o
        tráfego entre nossos servidores ou entre o seu computador e nossos
        servidores é encriptado através do protocolo seguro SSL ou
        semelhante.</font
      >
    </p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >Tomamos medidas de boas práticas e certificações existentes no mercado
        para garantir que os dados que coletamos sejam processados de acordo com
        segurança onde quer que os dados estejam localizados.</font
      >
    </p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <ol type="I" start="5">
      <li>
        <h2
          class="western"
          align="left"
          style="margin-top: 0cm; margin-bottom: 0cm"
        >
          <font face="Calibri, serif"
            >QUANTO TEMPO VAMOS ARMAZENAR SUAS INFORMAÇÕES</font
          >
        </h2>
      </li>
    </ol>

    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >Nós manteremos as informações que coletamos de você até que ocorra a
        solicitação de exclusão definitiva por sua parte. Neste caso, nós
        cessaremos imediatamente a utilização dos seus dados para fins
        comerciais, porém armazenaremos os seus dados enquanto tenhamos
        utilização comercial, obrigações legais, tributárias ou judiciais a
        cumprir com tais dados.
      </font>
    </p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <ol type="I" start="6">
      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"
            ><b>DADOS DO ENCARREGADO PELOS DADOS ( DPO)</b></font
          >
        </p>
      </li>
    </ol>

    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >A gestão do seus dados é feita por Gabriela Ferolla, e você pode entrar
        em contato com o Encarregado pelos seus dados através do e-mail:
        gabriela@sealegacy.com.br. Você também tem o direito de contatar
        diretamente a Autoridade de Proteção de Dados brasileira.
      </font>
    </p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <ol type="I" start="7">
      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"
            ><b>DIREITOS DO TITULAR DOS DADOS</b></font
          >
        </p>
      </li>
    </ol>

    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >O titular dos dados pessoais tem direito a obter do controlador, em
        relação aos dados do titular por ele tratados, a qualquer momento e
        mediante requisição:</font
      >
    </p>
    <p
      class="western"
      style="margin-left: 1.25cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif"
        >I - confirmação da existência de tratamento;</font
      >
    </p>
    <p
      class="western"
      style="margin-left: 1.25cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif">II - acesso aos dados;</font>
    </p>
    <p
      class="western"
      style="margin-left: 1.25cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif"
        >III - correção de dados incompletos, inexatos ou desatualizados;</font
      >
    </p>
    <p
      class="western"
      style="margin-left: 1.25cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif"
        >IV - anonimização, bloqueio ou eliminação de dados desnecessários,
        excessivos ou tratados em desconformidade com o disposto nesta
        Lei;</font
      >
    </p>
    <p
      class="western"
      style="margin-left: 1.25cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif"
        >V - portabilidade dos dados a outro fornecedor de serviço ou produto,
        mediante requisição expressa, de acordo com a regulamentação da
        autoridade nacional, observados os segredos comercial e
        industrial;</font
      >
    </p>
    <p
      class="western"
      style="margin-left: 1.25cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif"
        >VI - eliminação dos dados pessoais tratados com o consentimento do
        titular, exceto nas hipóteses previstas no art. 16 da LGPD;</font
      >
    </p>
    <p
      class="western"
      style="margin-left: 1.25cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif"
        >VII - informação das entidades públicas e privadas com as quais o
        controlador realizou uso compartilhado de dados;</font
      >
    </p>
    <p
      class="western"
      style="margin-left: 1.25cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif"
        >VIII - informação sobre a possibilidade de não fornecer consentimento e
        sobre as consequências da negativa;</font
      >
    </p>
    <p
      class="western"
      style="margin-left: 1.25cm; margin-top: 0cm; margin-bottom: 0cm"
    >
      <font face="Calibri, serif"
        >IX - revogação do consentimento, nos termos do § 5º do art. 8º da
        LGPD.</font
      >
    </p>
    <h2 class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></h2>
    <ol type="I" start="8">
      <li>
        <h2 class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"
            >COMO ACESSAR E CONTROLAR SUAS INFORMAÇÕES PESSOAIS</font
          >
        </h2>
      </li>
    </ol>

    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
      <font face="Calibri, serif"
        >Você pode solicitar informações, alteração, esclarecimentos ou exclusão
        de seus dados por meio do contato gabriela@sealegacy.com.br. Vamos
        exercer imediatamente as solicitações, nos termos da lei de proteção de
        dados aplicável.</font
      >
    </p>
    <p class="western" style="margin-top: 0cm; margin-bottom: 0cm"><br /></p>
    <ol type="I" start="9">
      <li>
        <p class="western" style="margin-top: 0cm; margin-bottom: 0cm">
          <font face="Calibri, serif"><b>CONSIDERAÇÃO FINAL</b></font>
        </p>
      </li>
    </ol>

    <p
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font color="#000000"
        ><font face="Calibri, serif"
          >Revisamos regularmente e, se apropriado, atualizamos este termo de
          consentimento e nossa política de privacidade de tempos em tempos, e
          conforme nossos serviços e uso de dados pessoais evoluem.</font
        ></font
      ><font color="#000000"><font face="Calibri, serif"> </font></font>
    </p>
    <p
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
    </p>
    <p
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font color="#000000"
        ><font face="Calibri, serif"
          >Se quisermos usar seus dados pessoais de uma forma que não
          identificamos anteriormente, entraremos em contato para fornecer
          informações sobre isso e, se necessário, solicitar o seu
          consentimento.</font
        ></font
      >
    </p>
    <p
      class="western"
      align="left"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 115%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="center"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 115%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>POLÍTICA DE PRIVACIDADE (DE ACORDO COM A LGPD)</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="center"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 115%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font color="#000000"
            ><font size="3" style="font-size: 12pt">A </font></font
          ><font color="#000000"
            ><font size="3" style="font-size: 12pt"><b>SEALL</b></font></font
          ><font color="#000000"
            ><font size="3" style="font-size: 12pt"
              >, pessoa jurídica de direito privado, inscrita no CNPJ sob nº
              29.700.229/0001-10, com sede a Avenida João Pinheiro, nº 146, 10º
              Andar, bairro Lourdes, na cidade de Belo Horizonte/MG, valoriza a
              privacidade de seus usuários e criou a presente Política de
              Privacidade para demonstrar seu compromisso em garantir a sua
              proteção e a privacidade e seus dados pessoais, nos termos da Lei
              Geral de Proteção de Dados e demais leis que versam sobre o tema,
              além de descrever de que forma sua privacidade sob os dados
              pessoais é protegida ao ser coletada, tratada e armazenada em
              nossas plataformas digitais.</font
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font color="#000000"
            ><font size="3" style="font-size: 12pt"
              >Ao utilizar nossos serviços – plataforma SEALL Intelligence –,
              você entende que coletaremos e usaremos suas informações pessoais
              nas formas descritas nesta Política de Privacidade, sob as normas
              de Proteção de Dados (LGPD, Lei Federal 13.709/2018) e das demais
              normas do ordenamento jurídico brasileiro aplicáveis.</font
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font color="#000000"
            ><font size="3" style="font-size: 12pt"
              >Dessa forma, a SEALL., doravante denominada simplesmente como
              “SEALL”, no papel de Controladora de Dados, obriga-se ao disposto
              na presente Política de Privacidade.</font
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font color="#000000"
            ><font size="3" style="font-size: 12pt"
              >A presente Política de Privacidade se aplica, em geral, a todos
              os Usuários e potenciais Usuários dos serviços oferecidos pela
              SEALL através da plataforma SEALL Intelligence e resume como a
              SEALL poderá coletar, produzir, receptar, classificar, utilizar,
              acessar, reproduzir, transmitir, distribuir, processar, arquivar,
              armazenar, eliminar, avaliar ou controlar a informação, modificar,
              comunicar, transferir, difundir ou extrair os dados coletados,
              incluindo as informações de identificação pessoal, de acordo com
              as bases legais aplicáveis e todas as leis de privacidade e
              proteção de dados em vigor.</font
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font color="#000000"
            ><font size="3" style="font-size: 12pt"
              >Ao acessar e/ou utilizar a plataforma SEALL Intelligence, o
              Usuário declara ter no mínimo 18 (dezoito) anos e ter capacidade
              plena e expressa para a aceitação dos termos e condições desta
              Política de Privacidade para todos os fins de direito.</font
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font color="#000000"
            ><font size="3" style="font-size: 12pt"
              >Caso o Usuário não se enquadre na descrição acima e/ou não
              concorde, ainda que em parte, com os termos e condições contidos
              nesta Política de Privacidade, não deverá acessar e/ou utilizar os
              serviços oferecidos pela SEALL bem como os sites, aplicativos e
              serviços por ela operados.</font
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font color="#000000"
            ><font size="3" style="font-size: 12pt"
              >O Usuário está ciente de que fornece as informações necessárias
              ao regular e correto funcionamento da plataforma SEALL
              Intelligence de forma consciente e voluntária por meio do aceite e
              concordância com o presente termo.</font
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font color="#000000"
            ><font size="3" style="font-size: 12pt"
              >Quando o Usuário realiza o cadastro na plataforma SEALL
              Intelligence e/ou preenche os formulários oferecidos pela SEALL,
              determinados Dados Pessoais solicitados serão mantidos em sigilo e
              serão utilizados apenas para o propósito que motivou o
              cadastro.</font
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="left"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="left"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b
              >1. Quais dados coletamos sobre você e para qual finalidade?</b
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="left"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Nosso site e/ou plataforma coleta e utiliza alguns dados pessoais,
            de forma a viabilizar a prestação de serviços e aprimorar a
            experiência de uso.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="left"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    ></p>
    <p
      class="western"
      align="left"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b
              >1.1. Dados pessoais que poderão ser solicitados pelo titular</b
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Nome completo, CPF, endereço de e-mail, telefones fixo e celular e
            notas de corretagem.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>1.2. Dados pessoais coletados automaticamente</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Geolocalização, Endereço de IP e User-Agent* de acesso, os quais
            servirão para garantir segurança na prestação dos serviços e
            aprimorar a experiência do usuário.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >*User-Agent - O cabeçalho de requisição User-Agent é uma cadeia de
            caracteres característica que permite servidores e pares de rede
            identificar a aplicação, sistema operacional, fornecedor, e/ou
            versão do agente de usuário requisitante.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>2. Como coletamos os seus dados?</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Nesse sentido, a coleta dos seus dados pessoais, tais como nome
            completo, CPF, endereço de e-mail, telefones fixo e celular ocorre
            no ato do cadastro na plataforma SEALL Intelligence e durante o
            preenchimento dos formulários oferecidos pela SEALL.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Já os dados referentes a Geolocalização, Endereço de IP e
            User-Agent serão coletados automaticamente quando o usuário acessar
            a plataforma SEALL Intelligence.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>2.1. Consentimento</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >É a partir do seu consentimento que tratamos os seus dados
            pessoais. O consentimento é a manifestação livre, informada e
            inequívoca pela qual você autoriza a SEALL a tratar seus
            dados.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Assim, em consonância com a Lei Geral de Proteção de Dados, seus
            dados só serão coletados, tratados e armazenados mediante prévio e
            expresso consentimento.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >O seu consentimento evidencia o compromisso de transparência e
            boa-fé da SEALL para com seus usuários/clientes, seguindo as
            regulações legislativas pertinentes.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Dessa forma, ao acessar e utilizar a plataforma SEALL Intelligence,
            bem como ao clicar na caixa de aceitação da presente Política de
            Privacidade e fornecer seus dados pessoais, você está ciente e
            consentindo com todas as disposições desta Política de Privacidade,
            além de conhecer seus direitos e como exercê-los.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >A qualquer tempo e sem nenhum custo, você poderá revogar seu
            consentimento.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >É importante destacar que a revogação do consentimento para o
            tratamento dos dados pode implicar a impossibilidade da performance
            adequada de alguma funcionalidade da plataforma SEALL Intelligence
            que dependa da operação. Tais consequências serão informadas
            previamente.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>3. Quais são os seus direitos?</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >A SEALL assegura a seus usuários/clientes seus direitos de titular
            previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa
            forma, você pode, de maneira gratuita e a qualquer tempo:</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- Confirmar a existência de tratamento de dados, de maneira
            simplificada ou em formato claro e completo.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- Acessar seus dados, podendo solicitá-los em uma cópia legível sob
            forma impressa ou por meio eletrônico, seguro e idôneo.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- Corrigir seus dados, ao solicitar a edição, correção ou
            atualização destes.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- Limitar seus dados quando desnecessários, excessivos ou tratados
            em desconformidade com a legislação através da anonimização,
            bloqueio ou eliminação.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- Solicitar a portabilidade de seus dados, através de um relatório
            de dados cadastrais que a SEALL trata a seu respeito.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- Eliminar seus dados tratados a partir de seu consentimento,
            exceto nos casos previstos em lei.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- Revogar seu consentimento, desautorizando o tratamento de seus
            dados.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- Informar-se sobre a possibilidade de não fornecer seu
            consentimento e sobre as consequências da negativa.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>4. Como você pode exercer seus direitos de titular?</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Para exercer seus direitos de titular, você deve entrar em contato
            com a SEALL através dos seguintes meios disponíveis:</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >EMAIL: gabriela@sealegacy.com.br</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >De forma a garantir a sua correta identificação como titular dos
            dados pessoais objeto da solicitação, é possível que solicitemos
            documentos ou demais comprovações que possam comprovar sua
            identidade. Nessa hipótese, você será informado previamente.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b
              >5. Como e por quanto tempo seus dados serão armazenados?</b
            ></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Seus dados pessoais coletados pela SEALL serão utilizados e
            armazenados durante o tempo necessário para a prestação do serviço
            ou para que as finalidades elencadas na presente Política de
            Privacidade sejam atingidas, considerando os direitos dos titulares
            dos dados e dos controladores.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >De modo geral, seus dados serão mantidos enquanto a relação
            contratual entre você e a SEALL perdurar. Findado o período de
            armazenamento dos dados pessoais, estes serão excluídos de nossas
            bases de dados ou anonimizados, ressalvadas as hipóteses legalmente
            previstas no artigo 16 lei geral de proteção de dados, a
            saber:</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >I – cumprimento de obrigação legal ou regulatória pelo
            controlador;</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >II – estudo por órgão de pesquisa, garantida, sempre que possível,
            a anonimização dos dados pessoais;</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >III – transferência a terceiro, desde que respeitados os requisitos
            de tratamento de dados dispostos nesta Lei; ou</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >IV – uso exclusivo do controlador, vedado seu acesso por terceiro,
            e desde que anonimizados os dados.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Esclarecendo, informações pessoais sobre você que sejam
            imprescindíveis para o cumprimento de determinações legais,
            judiciais e administrativas e/ou para o exercício do direito de
            defesa em processos judiciais e administrativos serão mantidas, a
            despeito da exclusão dos demais dados.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >O armazenamento de dados coletados pela SEALL reflete o nosso
            compromisso com a segurança e privacidade dos seus dados. Empregamos
            medidas e soluções técnicas de proteção aptas a garantir a
            confidencialidade, integridade e inviolabilidade dos seus dados.
            Além disso, também contamos com medidas de segurança apropriadas aos
            riscos e com controle de acesso às informações armazenadas.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>6. O que fazemos para manter seus dados seguros?</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Para mantermos suas informações pessoais seguras, usamos
            ferramentas físicas, eletrônicas e gerenciais orientadas para a
            proteção da sua privacidade.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Aplicamos essas ferramentas levando em consideração a natureza dos
            dados pessoais coletados, o contexto e a finalidade do tratamento e
            os riscos que eventuais violações gerariam para os direitos e
            liberdades do titular dos dados coletados e tratados.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Entre as medidas que adotamos, destacamos as seguintes:</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- Apenas pessoas autorizadas têm acesso a seus dados
            pessoais.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- O acesso a seus dados pessoais é feito somente após o compromisso
            de confidencialidade.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >- Seus dados pessoais são armazenados em ambiente seguro e
            idôneo.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >A SEALL se compromete a adotar as melhores posturas para evitar
            incidentes de segurança. Contudo, é necessário destacar que nenhuma
            página virtual é inteiramente segura e livre de riscos. É possível
            que, apesar de todos os nossos protocolos de segurança, problemas de
            culpa exclusivamente de terceiros ocorram, como ataques cibernéticos
            de hackers, ou também em decorrência da negligência ou imprudência
            do próprio usuário/cliente.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Em caso de incidentes de segurança que possa gerar risco ou dano
            relevante para você ou qualquer um de nossos usuários/clientes,
            comunicaremos aos afetados e a Autoridade Nacional de Proteção de
            Dados sobre o ocorrido, em consonância com as disposições da Lei
            Geral de Proteção de Dados.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>7. Com quem seus dados podem ser compartilhados?</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Tendo em vista a preservação de sua privacidade, a SEALL somente
            compartilhará seus dados com parceiros estritamente vinculados a
            atividade empresarial por si realizada com a única</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >finalidade de aprimorar a prestação de serviços e levar o
            conhecimento ao titular dos dados sob todas as novidades, serviços e
            produtos desenvolvidos.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Contudo, existem hipóteses em que seus dados poderão ser
            compartilhados, que são:</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >I – Determinação legal, requerimento, requisição ou ordem judicial,
            com autoridades judiciais, administrativas ou governamentais
            competentes.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >II – Caso de movimentações societárias, como fusão, aquisição e
            incorporação, de forma automática.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >III – Proteção dos direitos da TAX TRADER LTDA em qualquer tipo de
            conflito, inclusive os de teor judicial.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>8. Cookies ou dados de navegação</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >A SEALL poderá fazer o uso de Cookies, que são arquivos de texto
            enviados pela plataforma ao seu computador/dispositivo de acesso e
            que nele se armazenam, que contém informações relacionadas à
            navegação do site/plataforma. Em suma, os Cookies são utilizados
            para aprimorar a experiência de uso.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Ao acessar nossa plataforma e consentir com o uso de Cookies, você
            manifesta conhecer e aceitar a utilização de um sistema de coleta de
            dados de navegação com o uso de Cookies em seu dispositivo.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Você pode, a qualquer tempo e sem nenhum custo, alterar as
            permissões, bloquear ou recusar os Cookies. Todavia, a revogação do
            consentimento de determinados Cookies pode inviabilizar o
            funcionamento correto de alguns recursos da plataforma.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>9. Alteração desta Política de Privacidade</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >A atual versão da Política de Privacidade foi formulada e
            atualizada pela última vez em outubro do ano de 2021.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Reservamos o direito de modificar essa Política de Privacidade a
            qualquer tempo, principalmente em função da adequação a eventuais
            alterações feitas em nossa plataforma ou em âmbito legislativo. Por
            isso, recomendamos que você a revise com frequência.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Eventuais alterações entrarão em vigor a partir de sua publicação
            em nosso site e sempre lhe notificaremos acerca das mudanças
            ocorridas.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Ao utilizar nossos serviços e fornecer seus dados pessoais após
            tais modificações, você as consente.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>10. Responsabilidade</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >A SEALL prevê a responsabilidade dos agentes que atuam nos
            processos de tratamento de dados, em conformidade com os artigos 42
            ao 45 da Lei Geral de Proteção de Dados.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Nos comprometemos em manter esta Política de Privacidade
            atualizada, observando suas disposições e zelando por seu
            cumprimento.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Além disso, também assumimos o compromisso de buscar condições
            técnicas e organizativas seguramente aptas a proteger todo o
            processo de tratamento de dados.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Caso a Autoridade Nacional de Proteção de Dados exija a adoção de
            providências em relação ao tratamento de dados realizado pela (nome
            empresarial simplificado), comprometemo-nos a segui-las.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>10.1 Isenção de responsabilidade</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Conforme mencionado no “Item 06”, embora adotemos elevados padrões
            de segurança a fim de evitar incidentes, não há nenhuma
            página/ambiente virtual inteiramente livre de riscos. Nesse sentido,
            a SEALL não se responsabiliza por:</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >I – Quaisquer consequências decorrentes da negligência, imprudência
            ou imperícia dos usuários em relação a seus dados individuais.
            Garantimos e nos responsabilizamos apenas pela segurança dos
            processos de tratamento de dados e do cumprimento das finalidades
            descritas no presente instrumento.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Destacamos que a responsabilidade em relação à confidencialidade
            dos dados de acesso é do usuário.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >II – Ações maliciosas de terceiros, como ataques de hackers, exceto
            se comprovada conduta culposa ou deliberada da SEALL.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Destacamos que em caso de incidentes de segurança que possam gerar
            risco ou dano relevante para você ou qualquer um de nossos
            usuários/clientes, comunicaremos aos afetados e a Autoridade
            Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as
            providências necessárias.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >III – Inveracidade das informações inseridas pelo usuário/cliente
            nos registros necessários para a utilização dos serviços da SEALL;
            quaisquer consequências decorrentes de informações falsas ou
            inseridas de má-fé são de inteiramente responsabilidade do
            usuário/cliente.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <br />
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            ><b>11. Encarregado de Proteção de Dados</b></font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >A SEALL disponibiliza os seguintes meios para que você possa entrar
            em contato conosco para exercer seus direitos de titular, bem como,
            por cumprimento legal informa expressamente quem é seu Encarregado
            legal ( DPO ), sendo: Gabriela Ferolla, CEO da empresa SEALL, email
            gabriela@sealegacy.com.br.</font
          ></font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="
        margin-top: 0cm;
        margin-bottom: 0.35cm;
        line-height: 150%;
        orphans: 2;
        widows: 2;
      "
    >
      <font face="Calibri, serif"
        ><font size="2" style="font-size: 11pt"
          ><font size="3" style="font-size: 12pt"
            >Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os
            dados pessoais que tratamos, você pode entrar em contato com o nosso
            Encarregado de Proteção de Dados Pessoais acima destacado.</font
          ></font
        ></font
      >
    </p>
    <div title="footer">
      <p
        align="left"
        style="
          margin-top: 1.15cm;
          margin-bottom: 0cm;
          line-height: 100%;
          orphans: 2;
          widows: 2;
        "
      >
        <img
          src="../images/consentimento_html_3d012acc2e825774.gif"
          name="Shape1"
          alt="Shape1"
          align="left"
          vspace="1"
        />
        <span
          id="Frame1"
          dir="ltr"
          style="
            position: absolute;
            top: 28.87cm;
            left: -1.58cm;
            width: 17.74cm;
            height: 0.89cm;
            border: none;
            padding: 0cm;
            background: #ffffff;
          "
          ><p
            align="center"
            style="
              margin-top: 0cm;
              margin-bottom: 0.35cm;
              line-height: 115%;
              orphans: 2;
              widows: 2;
            "
          >
            <font face="Calibri, serif"
              ><font size="2" style="font-size: 11pt"
                >R. Maranhão, 166 – 10º Andar - Santa Efigênia, Belo Horizonte –
                MG, 30150-130</font
              ></font
            >
          </p> </span
        ><br />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "term",
};
</script>

<style>
</style>