import { createRouter, createWebHistory } from 'vue-router';
import Home from '../static/views/Home.vue';
import Login from '../static/views/Login.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login/:type?',
        name: 'Login',
        component: Login
    },
    {
        path: '/presentation',
        name: 'Presentation',
        component: () => import('../static/views/Presentation.vue')
    },
    {
        path: '/questions',
        name: 'Questions',
        component: () => import('../static/views/Questions.vue')
    },
    {
        path: '/organization',
        name: 'Organization',
        component: () => import('../static/views/Organization.vue')
    },
    {
        path: '/legacy',
        name: 'Legacy',
        component: () => import('../static/views/Legacy.vue')
    },
    {
        path: '/map',
        name: 'Map',
        component: () => import('../static/views/Map.vue')
    },
    {
        path: '/pdf',
        name: 'Pdf',
        component: () => import('../static/views/Generated.vue')
    },
    {
        path: '/mais',
        name: 'Mais',
        component: () => import('../static/views/Mais.vue')
    },
    {
        path: '/mais/gerenciar',
        name: 'Mais Management',
        component: () => import('../static/views/MaisManagement.vue')
    },
    {
        path: '/mais/fale-conosco',
        name: 'Mais Fale Conosco',
        component: () => import('../static/views/MaisFaleConosco.vue')
    },
    {
        path: '/esg',
        name: 'esg',
        component: () => import('../static/views/ESG.vue')
    },
    {
        path: '/esg/radar',
        name: 'esg-radar',
        component: () => import('../static/views/ESGRadar.vue')
    },
    {
        path: '/esg/radar/pdf',
        name: 'esg-pdf',
        component: () => import('../static/views/ESGPdf.vue')
    },
    {
        path: '/consad',
        name: 'consad',
        component: () => import('../static/views/Consad.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const publicPages = [
        '/login',
        '/login/esg',
        '/login/consad',
        '/login/g4',
        '/',
        '/presentation',
        '/mais',
        '/mais/fale-conosco',
        '/mais/gerenciar'
    ];
    const copyPublicPages = [...publicPages];
    // const params = Object.keys(to.params);

    // if (params.length > 0) {
    //     params.forEach((key) => {
    //         publicPages.forEach((p) => {
    //             copyPublicPages.push(`${p}/${to.params[key]}`);
    //         });
    //     });
    // }

    const authRequired = !copyPublicPages.includes(to.path);

    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;
