<template>
  <router-view />
  <notifications position="top right" />
</template>

<style lang="scss">
html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.valid {
  .select2-container--default .select2-selection--single {
    border: 1px solid #28a745 !important;
  }
}

.invalid {
  .select2-container--default .select2-selection--single {
    border: 1px solid #dc3545 !important;
  }
}

.select2-container {
  width: 100% !important;
}

.select2-container .select2-selection--single {
  height: 40px !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  margin-top: 5px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 7px !important;
}

.select2-selection__choice {
  max-width: 100%;
  display: flex;
  white-space: normal !important;
}

.text-error {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

button:disabled {
  opacity: 0.7 !important;
}

.font-italic {
  font-style: italic !important;
}
</style>