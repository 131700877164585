<template>
  <div id="login">
    <loading :active="loading" :is-full-page="true"></loading>
    <main class="main-login">
      <div class="main-login-svgs-create" v-if="isCreate">
        <svg width="140" height="140" class="svg-ods1">
          <circle cx="70" cy="40" r="70" fill="#FD6925" />
          <text
            fill="#ffffff"
            font-size="15"
            font-family="Nunito-Regular"
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
          />
        </svg>
        <svg width="140" height="140" class="svg-ods2">
          <circle cx="40" cy="70" r="70" fill="#E5243B" />
          <text
            fill="#ffffff"
            font-size="15"
            font-family="Nunito-Regular"
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
          />
        </svg>
        <svg width="140" height="140" class="svg-ods3">
          <circle cx="70" cy="70" r="70" fill="#FCC30B" />
          <text
            fill="#ffffff"
            font-size="15"
            font-family="Nunito-Regular"
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
          />
        </svg>
      </div>

      <div class="main-login-svgs-create" v-if="isLogin">
        <svg width="140" height="140" class="svg-ods4">
          <circle cx="70" cy="40" r="70" fill="#A21942" />
          <text
            fill="#ffffff"
            font-size="15"
            font-family="Nunito-Regular"
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
          />
        </svg>
        <svg width="140" height="140" class="svg-ods5">
          <circle cx="10" cy="70" r="70" fill="#26BDE2" />
          <text
            fill="#ffffff"
            font-size="15"
            font-family="Nunito-Regular"
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
          />
        </svg>
        <svg width="140" height="140" class="svg-ods6">
          <circle cx="70" cy="70" r="70" fill="#FD6925" />
          <text
            fill="#ffffff"
            font-size="15"
            font-family="Nunito-Regular"
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
          />
        </svg>
      </div>

      <div class="main-login-svgs-create" v-if="isConfirm">
        <svg width="200" height="200" class="svg-ods7">
          <circle cx="100" cy="100" r="100" fill="#FD6925" />
          <text
            fill="#ffffff"
            font-size="15"
            font-family="Nunito-Regular"
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
          />
        </svg>
        <svg width="140" height="140" class="svg-ods8">
          <circle cx="70" cy="70" r="70" fill="#FCC30B" />
          <text
            fill="#ffffff"
            font-size="15"
            font-family="Nunito-Regular"
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
          />
        </svg>
        <svg width="140" height="140" class="svg-ods9">
          <circle cx="70" cy="70" r="70" fill="#E5243B" />
          <text
            fill="#ffffff"
            font-size="15"
            font-family="Nunito-Regular"
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
          />
        </svg>
      </div>

      <div class="div-part-login">
        <div class="div-box">
          <div class="logo-div-img">
            <img
              src="./../images/logo-seall.png"
              alt="Logo SEALL"
              class="img-logo"
            />
          </div>

          <div class="div-links">
            <a
              href
              v-bind:class="{ 'seall-active': isCreate }"
              v-on:click.prevent="toggleisCreate('create')"
            >
              {{ $t("login.main.create") }}
            </a>
            <a
              href
              v-bind:class="{ 'seall-active': isLogin }"
              v-on:click.prevent="toggleisCreate('login')"
            >
              {{ $t("login.main.login") }}
            </a>
          </div>

          <div class="div-main-create" v-if="isCreate" key="create">
            <div class="div-inputs">
              <p>{{ $t("login.main.email") }}</p>
              <input
                type="email"
                v-model="user.register.email"
                v-bind:class="{
                  'border border-danger': errors_create.includes('email'),
                }"
                @change="checkEmail(user.register, 'create')"
                :placeholder="$t('login.main.placeh_email')"
              />
              <p
                v-if="errors_create.includes('email')"
                class="text text-danger"
              >
                {{ $t("login.main.email_invalid") }}
              </p>

              <p>{{ $t("login.main.pass") }}</p>
              <input
                type="password"
                v-model="user.register.password"
                v-bind:class="{
                  'border border-danger': errors_create.includes('password'),
                }"
                @change="checkPassword(user.register, 'create')"
                :placeholder="$t('login.main.placeh_pass')"
              />
              <p
                v-if="errors_create.includes('password')"
                class="text text-danger"
              >
                {{ $t("login.main.password_invalid") }}
              </p>
            </div>
            <div class="div-terms">
              <input type="checkbox" v-model="user.register.confirmTerm" />
              <p>
                <a href="#termsModal">{{ $t("login.create.term") }}</a>
              </p>
            </div>
            <div class="div-button">
              <button
                type="button"
                class="create-button"
                :disabled="
                  errors_create.length > 0 ||
                  !user.register.confirmTerm ||
                  !user.register.email ||
                  !user.register.password
                "
                v-on:click.prevent="register()"
              >
                <template v-if="$route.params.type === 'consad'">
                  Quero meu Certificado
                </template>
                <template v-else>
                  {{ $t("login.create.button") }}
                </template>
              </button>
            </div>
          </div>

          <div class="div-main-login" v-if="isLogin" key="login">
            <div class="div-inputs">
              <p>{{ $t("login.main.email") }}</p>
              <input
                type="email"
                v-model="user.login.email"
                v-bind:class="{
                  'border border-danger': errors_login.includes('email'),
                }"
                @change="checkEmail(user.login, 'login')"
                :placeholder="$t('login.main.placeh_email')"
              />
              <p v-if="errors_login.includes('email')" class="text text-danger">
                {{ $t("login.main.email_invalid") }}
              </p>

              <p>{{ $t("login.main.pass") }}</p>
              <input
                type="password"
                v-model="user.login.password"
                v-bind:class="{
                  'border border-danger': errors_login.includes('password'),
                }"
                @change="checkPassword(user.login, 'login')"
                :placeholder="$t('login.main.placeh_pass')"
              />
              <p
                v-if="errors_login.includes('password')"
                class="text text-danger"
              >
                {{ $t("login.main.password_invalid") }}
              </p>
            </div>
            <div class="div-forgot">
              <p>
                <a href="#forgotModal" v-on:click="clearForgotItems()">
                  {{ $t("login.login.link") }}
                </a>
              </p>
            </div>
            <div class="div-button">
              <button
                type="button"
                class="create-button"
                v-on:click.prevent="login()"
              >
                {{ $t("login.login.button") }}
              </button>
            </div>
          </div>

          <div class="div-main-confirm" v-if="isConfirm" key="confirm">
            <div class="div-inputs">
              <p style="text-align: center">{{ $t("login.create.message") }}</p>
              <input
                type="password"
                :placeholder="$t('login.create.placeh_code')"
              />
            </div>
            <div class="div-forgot">
              <p>{{ $t("login.create.resend") }}</p>
            </div>
            <div class="div-button">
              <router-link to="/presentation" class="links-header">
                <button type="button" class="create-button">
                  {{ $t("login.create.confirm") }}
                </button>
              </router-link>
              <a href v-on:click.prevent="toggleisCreate('create')">
                {{ $t("login.create.back") }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="div-create-img" v-if="isCreate" key="img-create"></div>

      <div class="div-login-img" v-if="isLogin" key="img-login"></div>
    </main>

    <footer>
      <div id="termsModal" class="modalDialog">
        <div>
          <h2>{{ $t("login.create.use_term") }}</h2>
          <div style="max-height: 300px; overflow-y: auto">
            <term></term>
          </div>
          <div class="modalDialog-btns">
            <a href="#close" title="Close" class="close">
              <button type="button" class="legacy-modal-button-back">
                {{ $t("login.create.button_close") }}
              </button>
            </a>
          </div>
        </div>
      </div>

      <div id="forgotModal" class="modalDialog">
        <div>
          <h2>{{ $t("login.forgot.title") }}</h2>
          <p>{{ $t("login.forgot.subtitle") }}</p>

          <div class="p-1">
            <div
              v-if="user.forgot.hasError"
              class="alert alert-warning"
              role="alert"
            >
              {{ $t("login.forgot.message_warn") }}
            </div>

            <div
              class="form-group mt-3 row"
              v-if="!this.user.forgot.requestVerificationCode"
            >
              <div class="col-10">
                <input
                  class="form-control"
                  type="email"
                  name
                  id
                  v-model="user.forgot.username"
                  :placeholder="$t('login.main.email')"
                />
              </div>
              <div class="col-2">
                <button
                  type="button"
                  class="forgot-button"
                  v-on:click="sendVerificationCode()"
                >
                  {{ $t("login.forgot.button_next") }}
                </button>
              </div>
            </div>
            <div v-else>
              <div class="form-group mt-3 row">
                <div class="col-2">
                  <p>{{ $t("login.forgot.verification_code") }}</p>
                </div>
                <div class="col-10">
                  <input
                    class="form-control"
                    type="text"
                    name
                    id
                    v-model="user.forgot.confirmationCode"
                    :placeholder="$t('login.forgot.verification_code')"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-2">
                  <p>{{ $t("login.forgot.new_password") }}</p>
                </div>
                <div class="col-10">
                  <input
                    class="form-control"
                    type="password"
                    name
                    id
                    v-model="user.forgot.newPassword"
                    :placeholder="$t('login.forgot.new_password')"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modalDialog-btns">
            <a ref="close" href="#close" title="Close" class="close">
              <button type="button" class="legacy-modal-button-back">
                {{ $t("login.create.button_close") }}
              </button>
            </a>
            <button
              class="forgot-button"
              v-if="canSave()"
              v-on:click="changePassword()"
            >
              {{ $t("login.forgot.recover_password") }}
            </button>
          </div>
        </div>
      </div>
      <img src="./../images/barra_ods.svg" alt="Barra" style="width: 100%" />
    </footer>
  </div>
</template>

<script>
import AuthService from "../../services/auth/auth";
import OrganizationService from "../../services/organization/organization.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Term from "./Term.vue";
export default {
  name: "login",
  components: {
    Loading,
    Term,
  },
  data() {
    return {
      isCreate: true,
      isConfirm: false,
      isLogin: false,
      loading: false,
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
      user: {
        register: { email: "", password: "", confirmTerm: false },
        login: { email: "", password: "" },
        forgot: {
          username: "",
          newPassword: "",
          confirmationCode: "",
          requestVerificationCode: false,
          hasError: false,
        },
      },
      errors_create: [],
      errors_login: [],
      emailRules: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      passwordRules:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.redirectAfterLogin();
    }
  },
  methods: {
    toggleisCreate(action) {
      if (action == "create") {
        this.isCreate = true;
        this.isLogin = false;
      } else if (action == "login") {
        this.isCreate = false;
        this.isLogin = true;
      }
    },
    login() {
      this.loading = true;

      const from = this.$route.params.type;

      const user = {
        username: this.user.login.email,
        password: this.user.login.password,
        from: from ? from : "common",
      };

      if (user.username && user.password) {
        this.$store.dispatch("auth/login", user).then(
          () => {
            this.redirectAfterLogin();
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.$notify({
              title: this.$t("login.main.error_login"),
              type: "warn",
            });
          }
        );
      }
    },
    register() {
      const from = this.$route.params.type;

      const user = {
        username: this.user.register.email,
        email: this.user.register.email,
        password: this.user.register.password,
        from: from ? from : "common",
      };

      this.loading = true;

      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.loading = false;
          this.user.login.email = user.username;
          this.user.login.password = user.password;
          this.login();
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    sendVerificationCode() {
      if (this.user.forgot.username != "") {
        this.loading = true;
        AuthService.forgotPassword(this.user.forgot)
          .then(() => {
            this.user.forgot.requestVerificationCode = true;
            this.user.forgot.hasError = false;
          })
          .catch(() => {
            this.$notify({
              title: this.$t("login.forgot.message_warn"),
              type: "warn",
            });
            this.user.forgot.hasError = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    canSave() {
      return (
        this.user.forgot.confirmationCode != "" &&
        this.user.forgot.newPassword != "" &&
        this.user.forgot.username != ""
      );
    },
    changePassword() {
      if (this.canSave()) {
        this.loading = true;
        AuthService.changePassword(this.user.forgot)
          .then(() => {
            this.user.forgot.hasError = false;
            this.$refs.close.click();
            this.$notify({
              title: this.$t("login.forgot.message_success"),
            });
          })
          .catch(() => {
            this.$notify({
              title: this.$t("login.forgot.message_warn_two"),
              type: "warn",
            });
            this.user.forgot.hasError = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    clearForgotItems() {
      (this.user.forgot.username = ""),
        (this.user.forgot.newPassword = ""),
        (this.user.forgot.confirmationCode = ""),
        (this.user.forgot.requestVerificationCode = false);
    },
    checkEmail(user, type) {
      if (!this.emailRules.test(user.email)) {
        if (type === "create" && !this.errors_create.includes("email")) {
          this.errors_create.push("email");
        } else if (type === "login" && !this.errors_login.includes("email")) {
          this.errors_login.push("email");
        }
      } else {
        if (type === "create") {
          const index = this.errors_create.indexOf("email");
          if (index !== -1) {
            this.errors_create.splice(index, 1);
          }
        } else if (type === "login") {
          const index = this.errors_login.indexOf("email");
          if (index !== -1) {
            this.errors_login.splice(index, 1);
          }
        }
      }
    },
    checkPassword(user, type) {
      if (!this.passwordRules.test(user.password)) {
        if (type === "create" && !this.errors_create.includes("password")) {
          this.errors_create.push("password");
        } else if (
          type === "login" &&
          !this.errors_login.includes("password")
        ) {
          this.errors_login.push("password");
        }
      } else {
        if (type === "create") {
          const index = this.errors_create.indexOf("password");
          if (index !== -1) {
            this.errors_create.splice(index, 1);
          }
        } else if (type === "login") {
          const index = this.errors_login.indexOf("password");
          if (index !== -1) {
            this.errors_login.splice(index, 1);
          }
        }
      }
    },
    redirectAfterLogin() {
      const from = this.$route.params.type;

      if (AuthService.validateOrganizationCompletedRadar() && !from != "") {
        window.location = "/map";
      } else if (from === "esg") {
        window.location = "/esg";
      } else if (from === "consad") {
        window.location = "/consad";
      } else {
        window.location = "/questions";
      }
    },
  },
};
</script>

<style scoped>
@import "../css/bootstrap/bootstrap.min.css";
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}
body {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100vw;
}
.is-invalid {
  border: 1px solid red !important;
}
.main-login {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.main-login-svgs-create {
  position: absolute;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}
.svg-ods1 {
  padding: 0vh 0vw 0vh 35vw;
}
.svg-ods2 {
  position: absolute;
  padding: 30vh 0vw 0vh;
}
.svg-ods3 {
  position: absolute;
  padding: 70vh 0vw 0vh 45vw;
}
.svg-ods4 {
  position: absolute;
  padding: 0vh 0vw 0vh 30vw;
}
.svg-ods5 {
  position: absolute;
  padding: 25vh 0vw 0vh;
}
.svg-ods6 {
  position: absolute;
  padding: 75vh 0vw 0vh 5vw;
}
.svg-ods7 {
  position: absolute;
  padding: 20vh 0vw 0vh 50vw;
}
.svg-ods8 {
  position: absolute;
  padding: 50vh 0vw 0vh 45vw;
}
.svg-ods9 {
  position: absolute;
  padding: 40vh 0vw 0vh 65vw;
}
.div-part-login {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background-color: #533a8b;
  width: 50vw;
}
.div-box {
  z-index: 6;
  background: #ffffff;
  box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2),
    0px 6px 10px rgba(112, 119, 128, 0.14),
    0px 1px 18px rgba(112, 119, 128, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 70vh;
  min-height: 500px;
  width: 30vw;
}
.logo-div-img {
  padding: 5vh 0vw 0vw;
}
.div-main-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  height: 70%;
  width: 80%;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}
.div-main-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  height: 70%;
  width: 80%;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}
.div-main-confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  height: 70%;
  width: 80%;
  padding: 0vh 3vw;
}
.div-links {
  font-family: NunitoSans-Regular;
  font-size: 14px;
  color: #707780;
  margin: 0vh 0vw;
  padding: 3vh 0vw 0vh;
}
.div-links a {
  font-family: NunitoSans-Regular;
  font-size: 18px;
  color: #707780;
  margin: 0vh 0vw;
  padding: 3vh 1vw 0vh;
}
.div-inputs input {
  font-family: Nunito-Regular;
  background: #ffffff;
  border: 1px solid #c9baeb;
  border-radius: 4px;
  font-size: 14px;
  margin: 0vh 0vw;
  padding: 1rem;
  width: 100%;
}
.div-inputs p {
  font-family: Nunito-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}
.div-terms {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.div-terms input {
  width: 20%;
}
.div-terms p {
  font-family: Nunito-Regular;
  font-size: 12px;
  margin: 0vh 0vw;
  padding: 2vh 0vw;
}
.div-forgot {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.div-forgot p {
  font-family: Nunito-Regular;
  font-size: 12px;
  margin: 0vh 0vw;
  padding: 2vh 0vw;
}
.div-button {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 3vh 0vw;
  width: 100%;
}
.create-button {
  background-color: #533a8b;
  border-radius: 4px;
  color: #ffffff;
  font-family: NunitoSans-Regular;
  border-style: hidden;
  padding: 1vh 2vw;
  width: 100%;
  text-transform: uppercase;
}
.create-button:disabled {
  background-color: #9d9d9c;
  border-radius: 4px;
  color: #ffffff;
  font-family: NunitoSans-Regular;
  border-style: hidden;
  padding: 1vh 2vw;
  width: 100%;
}
.div-create-img {
  width: 50vw;
  height: 96vh;
  background-image: url("./../images/background-create.png");
  background-repeat: repeat;
  background-size: cover;
}
.div-login-img {
  width: 50vw;
  height: 96vh;
  background-image: url("./../images/background-login.png");
  background-repeat: repeat;
  background-size: cover;
}
.div-confirm-img {
  width: 50vw;
  height: 96vh;
  background-image: url("./../images/background-confirm.png");
  background-repeat: repeat;
  background-size: cover;
}
.div-main-confirm .div-button {
  display: flex;
  justify-content: space-between;
  padding: 0vh 3vw;
}
.div-main-confirm .div-button a {
  font-family: NunitoSans-Regular;
  font-size: 14px;
  color: #707780;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}
footer {
  background-color: #533a8b;
  height: 4vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}
footer img {
  min-height: 0vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}
.modalDialog {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}
.modalDialog:target {
  opacity: 1;
  pointer-events: auto;
}
.modalDialog > div {
  width: 40vw;
  margin: 10vh 30vw;
  position: relative;
  padding: 1vh 3vw;
  border-radius: 10px;
  background: #fff;
  background: -moz-linear-gradient(#fff, #999);
  background: -webkit-linear-gradient(#fff, #999);
  background: -o-linear-gradient(#fff, #999);
}
.modalDialog img {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}
.modalDialog h2 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 133%;
  text-align: center;
  color: #533a8b;
  margin: 0vh 0vw;
  padding-bottom: 5vh;
}
.modalDialog h3 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 133%;
  text-align: center;
  color: #9d9d9c;
  margin: 0vh 0vw;
  padding-bottom: 2vh;
}
.modalDialog p {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  text-align: center;
  color: #9d9d9c;
  margin: 0vh 0vw;
  padding-bottom: 0vh;
}
.modalDialog-btns {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 5vh 0vw 0vh 0vw;
}
.forgot-button {
  background-color: #533a8b;
  border-radius: 4px;
  color: #ffffff;
  font-family: NunitoSans-Regular;
  border-style: hidden;
  padding: 1vh 0vw;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  width: 100%;
  height: 100%;
}
.legacy-modal-button-back {
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 8px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 1vw;
  padding: 1vh 2vw;
  cursor: pointer;
}
@media screen and (max-width: 1025px) {
  html,
  body {
    border: 0vh 0vw;
    height: 10%;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .main-login svg {
    position: absolute;
    padding: 40vh 0vw 0vh 20vw;
  }
  .div-part-login {
    width: 100%;
    height: 100vh;
  }
  .div-box {
    height: 70vh;
    width: 80vw;
  }
  .div-create-img {
    width: 0vw;
    height: 0vh;
  }
  .div-login-img {
    width: 0vw;
    height: 0vh;
  }
  .div-confirm-img {
    width: 0vw;
    height: 0vh;
  }
  footer {
    background-color: #533a8b;
    height: 1vh;
  }
  footer img {
    min-height: 1vh;
  }
  .modalDialog > div {
    width: 80vw;
    margin: 10vh 10vw;
  }
}
</style>
