import axios from 'axios';
import AuthService from '../auth/auth';
import authHeader from '../auth/auth-header';

const baseUrl = process.env.VUE_APP_BACKEND_API;

const httpClient = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});
const authInterceptor = (config) => {
    config.headers['Authorization'] = authHeader();
    return config;
};
const errorInterceptor = (error) => {
    if (!error.response) {
        return Promise.reject(error);
    }
    switch (error.response.status) {
        case 401: // authentication error, logout the user
            AuthService.refreshToken().finally(() => {
                AuthService.logout();
                window.location.reload(true);
            });
            break;
        default:
    }
    return Promise.reject(error);
};
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use((response) => {
    return response;
}, errorInterceptor);

export default httpClient;
