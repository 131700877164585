import axios from 'axios';
import jwt_decode from 'jwt-decode';

const API_URL = process.env.VUE_APP_BACKEND_API + 'auth/';

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'signin', {
                username: user.username,
                password: user.password,
                orgType: user.from
            })
            .then((response) => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    localStorage.setItem(
                        'organization',
                        JSON.stringify(response.data.organization)
                    );
                }
                return response.data;
            });
    }

    register(user) {
        return axios.post(API_URL + 'signup', {
            username: user.username,
            email: user.email,
            password: user.password,
            orgType: user.from
        });
    }

    refreshToken() {
        return axios
            .post(API_URL + 'token/refresh', {
                refreshToken: JSON.parse(localStorage.getItem('user')).refreshToken
            })
            .then((response) => {
                const user = JSON.parse(localStorage.getItem('user'));
                user.accessToken = response.data.accessToken;
                localStorage.setItem('user', JSON.stringify(user));
            });
    }

    forgotPassword(user) {
        return axios.post(API_URL + 'password/forgot', {
            username: user.username
        });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('organization');
    }

    changePassword(user) {
        const requestBody = {
            username: user.username,
            newPassword: user.newPassword,
            confirmationCode: user.confirmationCode
        };
        return axios.post(API_URL + 'password/change', requestBody);
    }

    getMaisOrganizationByCNPJ(cnpj) {
        return axios.get(`${API_URL}mais-organization/${cnpj}`);
    }

    maisOrganization(maisOrganization, step) {
        return axios.post(`${API_URL}mais-organization/${step}`, maisOrganization);
    }

    verificationCode(code) {
        return axios.post(`${API_URL}verification-code`, code);
    }

    getCogId() {
        return jwt_decode(JSON.parse(localStorage.getItem('user')).accessToken).sub;
    }

    getEmail() {
        return JSON.parse(localStorage.getItem('user')).username;
    }

    validateOrganizationNotExists() {
        const org = JSON.parse(localStorage.getItem('organization'), true);
        return !org || !org.id;
    }
    validateOrganizationCompletedRadar() {
        const org = JSON.parse(localStorage.getItem('organization'), true);
        return org && org.completedRadar;
    }
    getMaisOrganizations() {
        return axios.get(`${API_URL}mais-organization/bi`);
    }
}

export default new AuthService();
